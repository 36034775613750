// src/pages/Dashboard/Profile/StatusPage.tsx
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { LiaQrcodeSolid } from "react-icons/lia";
import connectaiIconPurple from "../../../assets/images/new-icons/connectai-icon-purple.svg";
import whatsappLogo from "../../../assets/images/new-icons/wpp-green-icon-square.png";
import "../../../assets/scss/custom/pages/_status-page.scss";
import { URLS } from "../../../constants";
import { toast, ToastContainer } from "react-toastify";
import { Session } from "../types";
import { AccountNameForm } from "./NewProfile";
import { WebhookQrModal } from "./GetQRCode";
import { UpdateChatHistoryModal } from "./UpdateChatHistoryModal";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  StateType,
} from "../../../utils/webSocketUtils";
import Cookies from "js-cookie";

// Material UI Components
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";

interface StatusPageProps {
  role: string;
  sessions: Session[];
  updateSessions?: () => void;
  loadingSearch: boolean;
  loadingControl: (value: boolean) => void;
  activeTour: () => void;
  isAgentEnabled?: boolean;
}

const StatusPage = forwardRef<HTMLButtonElement, StatusPageProps>(
  (
    {
      sessions,
      role,
      updateSessions,
      loadingSearch,
      loadingControl,
      activeTour,
      isAgentEnabled,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    // Dropdown state for session deletion
    const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement>(
      null,
    );
    const [dropdownSessionName, setDropdownSessionName] = useState<
      string | null
    >(null);

    // Other component states
    const [message, setMessage] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredSessions, setFilteredSessions] = useState<Session[]>([]);
    const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
    const [qrCodeModalOpen, setQrCodeModalOpen] = useState<boolean>(false);
    const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [sessionToDelete, setSessionToDelete] = useState<Session | null>(
      null,
    );
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [state, setState] = useState<StateType>({
      qrCode: null,
      showQrCode: false,
      webSocketLoading: true,
      loading: false,
      status: null,
      error: null,
    });

    const buttonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement);

    useEffect(() => {
      setFilteredSessions(sessions);
    }, [sessions]);

    // Format phone number helper
    const formatPhoneNumber = (phoneNumber: string): string => {
      let countryCode, areaCode, mainNumber, formattedNumber;
      if (phoneNumber.startsWith("971")) {
        countryCode = "971";
        areaCode = phoneNumber.slice(3, 5);
        mainNumber = phoneNumber.slice(5);
        formattedNumber =
          mainNumber.length === 7
            ? `${mainNumber.slice(0, 3)}-${mainNumber.slice(3)}`
            : `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`;
        return `+${countryCode} ${areaCode} ${formattedNumber}`;
      } else if (phoneNumber.startsWith("55")) {
        countryCode = "55";
        const numberWithoutCountryCode = phoneNumber.slice(2);
        areaCode = numberWithoutCountryCode.slice(0, 2);
        mainNumber = numberWithoutCountryCode.slice(2);
        formattedNumber =
          mainNumber.length === 8
            ? `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`
            : `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;
        return `+${countryCode} ${areaCode} ${formattedNumber}`;
      } else if (phoneNumber.startsWith("1")) {
        countryCode = "1";
        const numberWithoutCountryCode = phoneNumber.slice(1);
        areaCode = numberWithoutCountryCode.slice(0, 3);
        mainNumber = numberWithoutCountryCode.slice(3);
        formattedNumber = `${mainNumber.slice(0, 3)}-${mainNumber.slice(3)}`;
        return `+${countryCode} (${areaCode}) ${formattedNumber}`;
      } else {
        return phoneNumber;
      }
    };

    // Returns a status label based on session status
    const getStatusLabel = (status: string): string => {
      switch (status) {
        case "WORKING":
          return t("Connected");
        case "STOPPED":
          return t("Disconnected");
        case "SCAN_QR_CODE":
          return t("Scan QR Code");
        case "STARTING":
          return t("Starting");
        default:
          return t("Disconnected");
      }
    };

    // Returns a status color for the pill
    const getStatusColor = (status: string): string => {
      switch (status) {
        case "WORKING":
          return "#4CAF50";
        case "STOPPED":
          return "#414042";
        case "SCAN_QR_CODE":
          return "#FFA500";
        case "STARTING":
          return "#4C3699";
        default:
          return "#9E9E9E";
      }
    };

    // When a status pill with "SCAN_QR_CODE" is clicked, open the QR code modal
    const handlePillClick = (status: string) => {
      if (status === "SCAN_QR_CODE") {
        toggleQrCodeModal();
      }
    };

    const handleDropdownClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      sessionName: string,
    ) => {
      setDropdownAnchor(event.currentTarget);
      setDropdownSessionName(sessionName);
    };
    const handleDropdownClose = () => {
      setDropdownAnchor(null);
      setDropdownSessionName(null);
    };

    // Delete session function (calls backend)
    const apiKey = Cookies.get("api-key");
    const handleDeleteSession = async () => {
      if (sessionToDelete) {
        if (loadingControl) loadingControl(true);
        try {
          const response = await fetch(
            `${URLS.CLIENT_URL}/api/session_delete`,
            {
              method: "POST",
              headers: {
                APIKEY: apiKey || "",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ name: sessionToDelete.name }),
            },
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          setMessage(t("Session deleted successfully!"));
          setSessionToDelete(null);
          setConfirmModalOpen(false);
          if (updateSessions) updateSessions();
        } catch (error) {
          console.error("Failed to delete session:", error);
        } finally {
          if (loadingControl) loadingControl(false);
        }
      }
    };

    const [updateChatHistoryModalOpen, setUpdateChatHistoryModalOpen] = useState(false);
    const [selectedSession, setSelectedSession] = useState<string | null>(null);

    const handleOpenUpdateChatHistory = (session: Session) => {
      console.log("🟢 Abrindo modal de atualização para a sessão:", session.name);
      setSelectedSession(session.name); // ✅ Agora armazenamos apenas o nome (string)
      setUpdateChatHistoryModalOpen(true);
    };

    const handleCloseUpdateChatModal = () => {
      console.log("🔴 Fechando modal de atualização do histórico");
      setUpdateChatHistoryModalOpen(false); // ✅ Agora fecha a modal certa
    };

    const confirmDeleteSession = (session: Session) => {
      setSessionToDelete(session);
      setConfirmModalOpen(true);
    };

    // Handle search input
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      setSearchTerm(searchValue);
      if (!searchValue) {
        setFilteredSessions(sessions);
      } else {
        const filtered = sessions.filter(s =>
          s.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
        setFilteredSessions(filtered);
      }
    };

    // Toggle account creation modal
    const toggleAccountModal = (channel: string) => {
      if (sessions.length >= 4) {
        toast.error(t("Session limit"));
      } else {
        setSelectedChannel(channel);
        setAccountModalOpen(!accountModalOpen);
      }
    };

    const closeAccountModal = () => {
      setAccountModalOpen(false);
    };

    // Toggle QR code modal (with WebSocket logic)
    const toggleQrCodeModal = () => {
      if (!qrCodeModalOpen) {
        const newSocket = connectWebSocket(
          URLS.WEBSOCKET_URL + "/api/ws",
          event =>
            handleWebSocketMessage(
              event,
              setState,
              t("QR code successfully scanned!"),
            ),
          event =>
            handleWebSocketError(
              event,
              setState,
              t("WebSocket error observed"),
            ),
        );
        setSocket(newSocket);
      } else {
        if (socket) {
          socket.close();
          setSocket(null);
        }
      }
      setQrCodeModalOpen(!qrCodeModalOpen);
    };

    const toggleConfirmModal = () => {
      setConfirmModalOpen(!confirmModalOpen);
    };

    // Helper: truncate text with ellipsis
    const truncateText = (text: string, maxLength: number): string => {
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength) + "...";
    };

    // @ts-ignore
    // @ts-ignore
    return (
      <Container className="status-page-container" sx={{ py: 4 }}>
        <ToastContainer />

        {/* Loading Spinner */}
        <Box
          className="div-area"
          sx={{
            display: loadingSearch ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>

        {/* With Sessions */}
        {sessions.length > 0 && !loadingSearch && (
          <Box
            className="sessions-area"
            sx={{ mb: sessions.length > 0 ? 2 : 0 }}
          >
            {/* Search Bar & New Session Button */}
            <Box
              className="d-flex align-items-center mb-2"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                flexWrap: { xs: "wrap", md: "nowrap" },
                gap: 2,
              }}
            >
              <Box
                className="search-bar"
                sx={{
                  width: { xs: "100%", md: "38.13rem" },
                  height: "4.38rem",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "0.53rem",
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t("Search sessions...")}
                  value={searchTerm}
                  onChange={handleSearch}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: 14 },
                  }}
                />
                <IconButton>
                  <SearchIcon sx={{ color: "#414042" }} />
                </IconButton>
              </Box>
              {role === "admin" && (
                <Box
                  className="d-flex align-items-center"
                  sx={{ position: "relative" }}
                >
                  <Button
                    ref={buttonRef}
                    sx={{
                      width: "3.81rem",
                      height: "3.81rem",
                      borderRadius: "0.53rem",
                    }}
                    onClick={() => toggleAccountModal("Waha")}
                    className="d-flex align-items-center"
                  >
                    <Box
                      component="img"
                      src={connectaiIconPurple}
                      alt={t("Logo")}
                      sx={{ width: "100%", height: "100%" }}
                    />
                  </Button>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-0.31rem",
                      right: "-0.31rem",
                      width: "1.25rem",
                      height: "1.25rem",
                      backgroundColor: "#414042",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ color: "#fff", fontSize: 12 }}
                    >
                      +
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            {filteredSessions.length === 0 && searchTerm && (
              <Box sx={{ color: "red", fontSize: 14, mt: 0.5 }}>
                {t("No results found")}
              </Box>
            )}

            <Grid container spacing={2}>
              {filteredSessions.map((session) => (
                <Grid item xs={12} md={6} key={session.id}>
                  <Box
                    sx={{
                      backgroundColor: "#F6F6F6",
                      borderRadius: "0.53rem",
                      maxWidth: "22.6rem",
                      minHeight: "7.56rem",
                      p: 2,
                      width: { xs: "100%", sm: "auto" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: 1,
                    }}
                  >
                    {/* Left: WhatsApp icon + name + phone */}
                    <Box
                      className="d-flex align-items-center"
                      sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    >
                      <Box
                        component="img"
                        src={whatsappLogo}
                        alt="WhatsApp icon"
                        sx={{ width: 40, height: 40 }}
                      />
                      <Box sx={{ alignSelf: "flex-start" }}>
                        <Tooltip title={session.name} arrow>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              mb: 0.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "12rem",
                            }}
                          >
                            {truncateText(session.name, 15)}
                          </Typography>
                        </Tooltip>
                        {session.phone_number && (
                          <Typography variant="body2" sx={{ color: "#414042" }}>
                            {formatPhoneNumber(session.phone_number)}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    {/* Right: Status pill, menu e botão de Atualizar Histórico */}
                    <Box
                      className="d-flex align-items-end"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        height: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        onClick={() => handlePillClick(session.status)}
                        sx={{
                          backgroundColor: getStatusColor(session.status),
                          color: "#fff",
                          borderRadius: "1rem",
                          px: 2,
                          py: 0.5,
                          mb: "auto",
                          cursor:
                            session.status === "SCAN_QR_CODE" ? "pointer" : "default",
                        }}
                      >
                        <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                          {getStatusLabel(session.status)}
                        </Typography>
                      </Box>

                      {role === "admin" && (
                        <IconButton onClick={(e) => handleDropdownClick(e, session.name)}>
                          <MoreVertIcon sx={{ color: "#414042" }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Without Sessions */}
        {sessions.length === 0 && !loadingSearch && (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Box>
                <Typography className="header-text mt-5 mb-4" variant="h5">
                  {t("Connect Jess to your")}
                </Typography>
                <ol
                  className="text-left instruction-list"
                  style={{ paddingLeft: "1.25rem", margin: 0 }}
                >
                  <li className="mb-2" style={{ marginBottom: "0.5rem" }}>
                    {t("Open WhatsApp on your phone")}
                  </li>
                  <li className="mb-2" style={{ marginBottom: "0.5rem" }}>
                    {t("Tap Menu on Android, or Settings on iPhone")}
                  </li>
                  <li className="mb-2" style={{ marginBottom: "0.5rem" }}>
                    {t("Tap Connected Devices and then Connect a device")}
                  </li>
                  <li className="mb-2" style={{ marginBottom: "0.5rem" }}>
                    {t(
                      "Press the button next to it, add a name to this session and point your phone's camera at the QR CODE that will appear.",
                    )}
                  </li>
                </ol>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: { xs: 2, lg: 0 },
                }}
              >
                <Button
                  ref={buttonRef}
                  variant="contained"
                  color="primary"
                  onClick={() => toggleAccountModal("Waha")}
                  sx={{ mb: 2 }}
                  className="add-session-btn-big"
                >
                  <Box
                    className="div-qrcode"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <LiaQrcodeSolid
                      className="text-qrcode"
                      style={{ fontSize: "2rem" }}
                    />
                    <Typography className="text-qrcode" sx={{ mt: 1 }}>
                      {t("Tap here to see the QR CODE")}
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{ borderBottom: "0.0625rem solid #ccc", my: "0.125rem" }}
            />
            <Typography sx={{ mt: 3, textAlign: "center" }}>
              {t("support_whatsapp_api")}
            </Typography>
            <Box className="div-area">
              <a
                onClick={activeTour}
                className="help-link"
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {t("Need help?")}
              </a>
            </Box>
          </Box>
        )}

        <Menu
          anchorEl={dropdownAnchor}
          open={Boolean(dropdownAnchor) && dropdownSessionName !== null}
          onClose={handleDropdownClose}
        >
          {/* MenuItem para deletar sessão */}
          <MenuItem
            onClick={() => {
              const s = filteredSessions.find(sess => sess.name === dropdownSessionName);
              if (s) {
                confirmDeleteSession(s);
              }
              handleDropdownClose();
            }}
          >
            {t("Delete session")}
          </MenuItem>

          {/* Novo MenuItem para atualizar histórico */}
          <MenuItem
            onClick={() => {
              const s = filteredSessions.find(sess => sess.name === dropdownSessionName);
              if (s) {
                handleOpenUpdateChatHistory(s);
              }
              handleDropdownClose();
            }}
            disabled={(() => {
              const session = filteredSessions.find(sess => sess.name === dropdownSessionName);
              return !(session?.status === "WORKING") || session?.is_historic_update;
            })()}
          >
            {t("Update History")}
          </MenuItem>
        </Menu>



        {/* Modals */}
        {role === "admin" && (
          <>
            {/* Create Session Modal */}
            <Dialog open={accountModalOpen} onClose={toggleAccountModal}>
              <DialogTitle sx={{ fontSize: "25px" }}>
                {t("Create Session")}
              </DialogTitle>
              <DialogContent>
                <AccountNameForm
                  isOpen={accountModalOpen}
                  toggle={() => setAccountModalOpen(false)}
                  updateSessions={updateSessions}
                  channel={selectedChannel ?? undefined}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeAccountModal}>{t("Close")}</Button>
              </DialogActions>
            </Dialog>

            {/* QR Code Modal */}
            <Dialog
              open={qrCodeModalOpen}
              onClose={toggleQrCodeModal}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>{t("QR Code")}</DialogTitle>
              <DialogContent>
                <WebhookQrModal
                  isOpen={qrCodeModalOpen}
                  toggle={toggleQrCodeModal}
                  existingSocket={socket}
                  qrCode={state.qrCode}
                  showQrCode={state.showQrCode}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleQrCodeModal}>{t("Close")}</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={updateChatHistoryModalOpen}
              onClose={handleCloseUpdateChatModal}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>{t("QR Code")}</DialogTitle>
              <DialogContent>
                <UpdateChatHistoryModal
                  isOpen={updateChatHistoryModalOpen}
                  toggle={handleCloseUpdateChatModal}
                  sessionName={selectedSession}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUpdateChatModal}>{t("Close")}</Button>
              </DialogActions>
            </Dialog>
          </>
        )}

        {/* Deletion Confirmation Modal */}
        <Dialog open={confirmModalOpen} onClose={toggleConfirmModal}>
          <DialogTitle sx={{ fontSize: "25px" }}>
            {t("Deletion Confirmation")}
          </DialogTitle>
          <DialogContent>
            {t("Are you sure you want to delete the session")}{" "}
            {sessionToDelete?.name}?
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={handleDeleteSession}
              disabled={loadingSearch}
            >
              {loadingSearch ? t("Deleting...") : t("Delete")}
            </Button>
            <Button color="secondary" onClick={toggleConfirmModal}>
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  },
);

export default StatusPage;
