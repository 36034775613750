import React from "react";
import Root from "../pages/Root/index";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RecoverPassword from "../pages/Authentication/RecoverPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import CreateNewPassword from "../../src/pages/Authentication/NewPassword";
import LockScreen from "../pages/Authentication/LockScreen";
import Dashboard from "../pages/Dashboard/index";
import StarterPage from "../pages/StarterPage/index";
import DownloadPage from "../pages/Download/DownloadPage";
import PaymentDetailsForm from "../pages/Payment/PaymentDetailsForm";
import PaymentMethodSelection from "../pages/Payment/PaymentMethodSelection";
import PaymentConfirmation from "../pages/Payment/PaymentConfirmation";
import SubscriptionDetails from "../pages/Payment/SubscriptionDetails";
import ManagePayment from "../pages/Payment/ManagePayment";
import ManageTiers  from "../pages/Payment/ManageTiers";
import CancelSubscription from "../pages/Payment/CancelSubscription";
import CancelConfirmation from "../pages/Payment/CancelConfirmation";
import ChatIndex from "../pages/Dashboard/Chats/index";
import StatusPageMock from "../pages/Dashboard/Profile/StatusPageMock";

interface RouteProps {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-login", component: <Login /> },
  { path: "/auth-register", component: <Register /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-changepassword/:token", component: <ChangePassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/logout", component: <Logout /> },
  { path: "/download", component: <DownloadPage /> },
  { path: "/payment", component: <PaymentMethodSelection /> },
  { path: "/payment-form", component: <PaymentDetailsForm /> },
  { path: "/confirmation", component: <PaymentConfirmation /> },
  { path: "/subscription", component: <SubscriptionDetails /> },
  { path: "/manage-payment", component: <ManagePayment /> },
  { path: "/manage-tiers", component: <ManageTiers /> },
  { path: "/cancel-subscription", component: <CancelSubscription /> },
  { path: "/cancel-confirmation", component: <CancelConfirmation /> },
  { path: "/status-mock", component: <StatusPageMock /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/download", component: <DownloadPage /> },
  { path: "/chat", component: <ChatIndex /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", component: <Root /> },
  { path: "/auth-create-new-password", component: <CreateNewPassword /> },
];

export { publicRoutes, privateRoutes,  };
