import { Box, Typography, ButtonBase } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ButtonBase
      onClick={() => navigate(-1)}
      sx={{
        position: "absolute",
        top: 16,
        left: 16,
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: "#fff",
        padding: "10px 16px",
        borderRadius: "8px",
        transition: "0.3s",
        "&:hover": {
          backgroundColor: "#3a277159",
        },
      }}
    >
      <Box
        sx={{
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "1.5rem", color: "#fff" }} />
      </Box>

      <Typography sx={{ fontSize: "1rem", fontWeight: "500", color: "#fff" }}>
        {t("back")}
      </Typography>
    </ButtonBase>
  );
};

export default BackButton;
