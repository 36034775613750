import { format, isValid, parseISO, parse } from "date-fns";

const formatDate = (date: any, formatPattern = "MM/dd/yyyy") => {
  let dateObj;

  if (!date) {
    console.warn("⚠️ Data não informada. Retornando string vazia.");
    return ""; // Retorna string vazia se a data for nula ou indefinida
  }

  if (typeof date === "string") {
    // Primeiro tenta converter como ISO
    dateObj = parseISO(date);

    // Se não for uma string ISO válida, tenta interpretar no formato recebido
    if (!isValid(dateObj)) {
      dateObj = parse(date, "M/d/yyyy, h:mm:ss a", new Date());
    }
  } else {
    dateObj = new Date(date); // Converte timestamps numéricos para Date
  }

  if (!isValid(dateObj)) {
    console.warn(`⚠️ Data inválida recebida:`, date);
    return ""; // Se a data for inválida, retorna string vazia
  }

  return format(dateObj, formatPattern);
};

export { formatDate };