import React, { useState, KeyboardEvent, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Box } from "@mui/material";

interface InputSectionProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

const InputSection = ({ value, onChange, onSubmit }: InputSectionProps) => {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        onChange(value + "\n");
      } else {
        e.preventDefault();
        onSubmit();
      }
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "b") {
      e.preventDefault();
      setIsBold(!isBold);
    }

    if ((e.ctrlKey || e.metaKey) && e.key === "i") {
      e.preventDefault();
      setIsItalic(!isItalic);
    }
  };

  const getFormattedText = () => {
    let formattedText = value;
    if (isBold) formattedText = `**${formattedText}**`;
    if (isItalic) formattedText = `*${formattedText}*`;
    return formattedText;
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${Math.min(
        textAreaRef.current.scrollHeight,
        80
      )}px`;
    }
  }, [value]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "rgb(255,255,255)",
        padding: "6px 10px",
        //borderTop: "1px solid #ddd",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder={t("enter_message")}
        value={getFormattedText()}
        onChange={e => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        multiline
        inputRef={textAreaRef}
        maxRows={3}
        sx={{
          backgroundColor: "#EEEEED",
          borderRadius: "12px",
          paddingLeft: 1,
          overflowY: "auto",
          minHeight: "20px",
          maxHeight: "50px",
          fontSize: "14px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
        }}
      />
    </Box>
  );
};

export default InputSection;
