import React, { useState } from "react";
import {
  Alert,
  Grid,
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RecoverPasswordLayoutWrapper from "../../components/RecoverPasswordLayoutWrapper";
import Cookies from "js-cookie";
import { URLS } from "../../constants";
import { Spinner } from "reactstrap";

interface FormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email."),
});

const defaultValues: FormValues = {
  email: "",
};

const RecoverPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [recoverPasswordError, setRecoverPasswordError] = useState<
    string | null
  >(null);
  const [recoverPasswordSuccess, setRecoverPasswordSuccess] = useState<
    string | null
  >(null);

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const apiKey = Cookies.get("api-key");
  const onSubmitForm = async (values: FormValues) => {
    setLoading(true);
    try {
      const response = await fetch(`${URLS.CLIENT_URL}/api/recover-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey || "",
        },
        body: JSON.stringify({
          email: values.email,
        }),
      });

      if (response.ok) {
        setRecoverPasswordSuccess(
          t("A password reset link has been sent to your email."),
        );
        setTimeout(() => {
          navigate("/auth-login");
        }, 3000);
      } else {
        const errorData = await response.json();
        setRecoverPasswordError(
          errorData.message ||
            t("An error occurred while sending the reset link."),
        );
      }
    } catch (error) {
      setRecoverPasswordError(
        t("An error occurred while sending the reset link."),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <RecoverPasswordLayoutWrapper>
      <Container maxWidth="sm">
        <Box py={5}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#414042",
              }}
            >
              {t("Change Password")}
            </Typography>
            <Tooltip title={t("reset_your_password.")} placement="right">
              <IconButton>
                <HelpOutlineIcon sx={{ color: "#4C3699" }} />
              </IconButton>
            </Tooltip>
          </Box>
          {recoverPasswordError && !loading && (
            <Alert severity="error">{recoverPasswordError}</Alert>
          )}
          {recoverPasswordSuccess && !loading && (
            <Alert severity="success">{recoverPasswordSuccess}</Alert>
          )}
          {loading ? (
            <div className="spinner d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={t("Email")}
                    type="email"
                    fullWidth
                    {...register("email")}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        borderRadius: "8px",
                        backgroundColor: "#4140421A",
                      },
                    }}
                    sx={{
                      "& .MuiFilledInput-root": {
                        "&:hover": {
                          backgroundColor: "#4140421A",
                        },
                        "&.Mui-focused": {
                          backgroundColor: "#4140421A",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Box textAlign="center" mt={4}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: "#4C3699",
                    borderRadius: "24px",
                    fontWeight: "bold",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "#36276F",
                    },
                  }}
                >
                  {t("Reset Password")}
                </Button>
              </Box>
            </form>
          )}
          <Box mt={5} textAlign="center">
            <Typography
              variant="body2"
              sx={{
                color: "#414042",
              }}
            >
              {t("Remember your password?")}{" "}
              <Button
                component="a"
                href="/auth-login"
                sx={{
                  textDecoration: "underline",
                  color: "#4C3699",
                  fontWeight: "bold",
                  borderRadius: "14px",
                  "&:hover": {
                    color: "#4C3699",
                    fontWeight: "bold",
                  },
                }}
              >
                {t("Login")}
              </Button>
            </Typography>
          </Box>
        </Box>
      </Container>
    </RecoverPasswordLayoutWrapper>
  );
};

export default RecoverPassword;
