import React from 'react';
import SideMenu from '../../layouts/Default/SideMenu';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChangePlanIcon from "../../assets/images/new-icons/change-plans-icon.svg";
import PaymentIcon from "../../assets/images/new-icons/payments-icon.svg";
import BuyCreditIcon from "../../assets/images/new-icons/buy-credits.svg";
import CancelSubscriptionIcon from "../../assets/images/new-icons/cancel-subscription.svg";

const SubscriptionDetails = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {/* Side Menu */}
      <SideMenu onChangeLayoutMode={() => {}} />

      {/*  Principal */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0 -160%",
          padding: "1.5em",
          position: "relative",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            maxWidth: "40.625rem",
            padding: "3.5rem 4.5rem",
            borderRadius: "1rem",
            backgroundColor: "#fff",
            color: "#fff",
            boxShadow: "0rem 0.5rem 1rem rgba(0, 0, 0, 0.2)",
            p: isMobile ? 2 : 4,
            overflowY: "auto",
            maxHeight: isMobile ? "70vh" : "none",
          }}
          elevation={4}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <AccountCircleIcon
                sx={{ fontSize: "3.75rem", color: "#4C3699" }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                sx={{ fontWeight: "700", color: "#4C3699", fontSize: "2.3rem" }}
              >
                {t("account")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#414042",
                  fontSize: "1.2rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  p: isMobile ? 2 : 3,
                }}
              >
                {t("subscriptionDetails")}
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              position: "relative",
              backgroundColor: "#F6F6F6",
              color: "#3A2771",
              borderRadius: "1rem",
              padding: "2.5rem",
              marginTop: "1rem",
              boxShadow: "0rem 0.25rem 0.5rem rgba(202, 202, 202, 0.1)",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "-0.0625em",
                left: "-0.0625em",
                backgroundColor: "#4C3699",
                borderRadius: "0.75rem 0rem",
                padding: "0.5rem 2.5rem",
                fontSize: "1.1rem",
                fontWeight: "700",
                color: "#fff",
                px: 2,
                py: 1,
              }}
            >
              {t("subscriberSince", { date: "August 2023" })}
            </Box>

            <Typography
              variant="body2"
              sx={{
                fontWeight: "700",
                mt: 2,
                color: "#414042",
                fontSize: "1.2rem",
              }}
            >
              {t("basicPlan")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "300",
                color: "#414042",
                fontSize: "1rem",
              }}
            >
              {t("nextPayment", { date: "November 3, 2024" })}
            </Typography>
            <Typography variant="body2">
              <span
                style={{
                  color: "#414042",
                  fontSize: "0.75rem",
                  fontWeight: "300",
                }}
              >
                ●●●● ●●●● ●●●●
              </span>{" "}
              1234
            </Typography>
          </Box>

          <List
            sx={{
              mt: "1.5rem",
              backgroundColor: "#F6F6F6",
              borderRadius: "1em",
              color: "#414042",
              boxShadow: "0rem 0.25rem 0.5rem rgba(202, 202, 202, 0.1)",
            }}
          >
            {/* change subscription */}
            <ListItem
              component={Link}
              to="/manage-tiers"
              sx={{
                "&:hover": { backgroundColor: "#EDEDED" },
              }}
            >
              <ListItemIcon
                sx={{
                  "& img": {
                    transition: "0.3s",
                  },
                  "&:hover img": {
                    transform: "scale(1.2)",
                    filter: "brightness(1.2)",
                  },
                }}
              >
                <img
                  src={ChangePlanIcon}
                  alt="Change Subscription"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary={t("changePlans")}
                sx={{ color: "#414042", fontWeight: "400", fontSize: "1.2em" }}
              />
              <ListItemSecondaryAction>
                <ArrowForwardIosIcon
                  sx={{ color: "#414042D1", fontSize: "1.125em" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider sx={{ marginLeft: "1em", marginRight: "1em" }} />

            {/* Payments */}
            <ListItem
              component={Link}
              to="/manage-payment"
              sx={{ "&:hover": { backgroundColor: "#EDEDED" } }}
            >
              <ListItemIcon
                sx={{
                  transition: "0.3s",
                  "& img": {
                    transition: "0.3s",
                  },
                  "&:hover img": {
                    transform: "scale(1.2)",
                    filter: "brightness(1.2)",
                  },
                }}
              >
                <img
                  src={PaymentIcon}
                  alt="Pagamentos"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary={t("payments")}
                sx={{ color: "#414042", fontWeight: "400", fontSize: "1.2em" }}
              />
              <ListItemSecondaryAction>
                <ArrowForwardIosIcon
                  sx={{ color: "#414042D1", fontSize: "1.125em" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider sx={{ marginLeft: "1em", marginRight: "1em" }} />

            {/* Cancel subscription */}
            <ListItem
              component={Link}
              to="/cancel-subscription"
              sx={{ "&:hover": { backgroundColor: "#EDEDED" } }}
            >
              <ListItemIcon
                sx={{
                  transition: "0.3s",
                  "& img": {
                    transition: "0.3s",
                  },
                  "&:hover img": {
                    transform: "scale(1.2)",
                    filter: "brightness(1.2)",
                  },
                }}
              >
                <img
                  src={CancelSubscriptionIcon}
                  alt="Cancel subscription"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary={t("cancelSub")}
                sx={{ color: "#414042", fontWeight: "400", fontSize: "1.2em" }}
              />
              <ListItemSecondaryAction>
                <ArrowForwardIosIcon
                  sx={{ color: "#414042D1", fontSize: "1.125em" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default SubscriptionDetails;
