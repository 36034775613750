import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Modal,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useRedux } from "../../hooks/index";
import { createSelector } from "reselect";
import { userChangePassword } from "../../redux/actions";
import RecoverPasswordLayoutWrapper from "../../components/RecoverPasswordLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import Loader from "../../components/Loader";
import { Spinner } from "reactstrap";

interface ChangePasswordProps {}

const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmpassword: false,
  });
  const [showModal, setShowModal] = useState(false);

  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.ForgetPassword,
    state => ({
      passwordChanged: state.passwordChanged,
      changepasswordError: state.changepasswordError,
      changePassLoading: state.loading,
    }),
  );

  const { passwordChanged, changepasswordError, changePassLoading } =
    useAppSelector(errorData);

  const resolver = yupResolver(
    yup.object().shape({
      password: yup
        .string()
        .required(t("Please Enter New Password."))
        .min(8, t("Password must be at least 8 characters."))
        .matches(
          /[A-Z]/,
          t("Password must contain at least one uppercase letter."),
        )
        .matches(
          /[@$!%*?&]/,
          t("Password must contain at least one special character."),
        ),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password")], t("Passwords don't match"))
        .required(t("This value is required.")),
    }),
  );

  const methods = useForm({
    defaultValues: { password: "", confirmpassword: "" },
    resolver,
    context: { abortEarly: false },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values: any) => {
    if (token) {
      dispatch(userChangePassword(values, token));
    } else {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate("/auth-login");
  };

  const handleClickShowPassword = (field: keyof typeof showPassword) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <RecoverPasswordLayoutWrapper>
      <Container maxWidth="sm">
        <Box py={5}>
          <AuthHeader title={t("Change Password")} />
          {changepasswordError && (
            <Alert severity="error">
              {JSON.stringify(changepasswordError)}
            </Alert>
          )}
          {passwordChanged && (
            <Box textAlign="center">
              <Alert severity="success">{t("Your Password is changed.")}</Alert>
              <Button
                variant="contained"
                onClick={() => navigate("/auth-login")}
                sx={{
                  backgroundColor: "#4C3699",
                  marginTop: "24px",
                  "&:hover": {
                    backgroundColor: "#36276F",
                  },
                }}
              >
                {t("Go to Login")}
              </Button>
            </Box>
          )}
          {changePassLoading ? (
            <div className="status d-flex justify-content-center align-items-center mt-3">
              <Spinner color="primary" />
            </div>
          ) : (
            !passwordChanged && (
              <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("New Password")}
                      type={showPassword.password ? "text" : "password"}
                      fullWidth
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password?.message || ""}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                            >
                              {showPassword.password ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "8px",
                          backgroundColor: "#4140421A",
                        },
                      }}
                      sx={{
                        "& .MuiFilledInput-root": {
                          "&:hover": {
                            backgroundColor: "#4140421A",
                          },
                          "&.Mui-focused": {
                            backgroundColor: "#4140421A",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("Confirm New Password")}
                      type={showPassword.confirmpassword ? "text" : "password"}
                      fullWidth
                      {...register("confirmpassword")}
                      error={!!errors.confirmpassword}
                      helperText={errors.confirmpassword?.message || ""}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword("confirmpassword")
                              }
                            >
                              {showPassword.confirmpassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "8px",
                          backgroundColor: "#4140421A",
                        },
                      }}
                      sx={{
                        "& .MuiFilledInput-root": {
                          "&:hover": {
                            backgroundColor: "#4140421A",
                          },
                          "&.Mui-focused": {
                            backgroundColor: "#4140421A",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Box textAlign="center" mt={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: "#4C3699",
                          borderRadius: "24px",
                          fontWeight: "bold",
                          padding: "10px",
                          "&:hover": {
                            backgroundColor: "#36276F",
                          },
                        }}
                        type="submit"
                      >
                        {t("Save")}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => navigate("/auth-login")}
                        sx={{
                          borderRadius: "24px",
                          fontWeight: "bold",
                          borderColor: "#4C3699",
                          color: "#4C3699",
                          "&:hover": {
                            backgroundColor: "#EAEAFF",
                            borderColor: "#36276F",
                          },
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )
          )}
        </Box>
      </Container>
      <Modal open={showModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" component="h2">
            {t("Invalid Token")}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {t(
              "The token is invalid or missing. You will be redirected to the login page.",
            )}
          </Typography>
          <Box textAlign="right" mt={4}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4C3699",
                "&:hover": {
                  backgroundColor: "#36276F",
                },
              }}
              onClick={handleModalClose}
            >
              {t("OK")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </RecoverPasswordLayoutWrapper>
  );
};

export default ChangePassword;
