import React, { useState, useEffect, ChangeEvent } from "react";
import SideMenu from "../../layouts/Default/SideMenu";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "../../components/BackButton";
import CreditCards from "../../assets/images/new-icons/credit-cards.png";
import InputMask from "react-input-mask";
// import ReCAPTCHA from "react-google-recaptcha"; // TODO: Implement reCAPTCHA integration

const PaymentDetailsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  // Form state
  const [cardType, setCardType] = useState("credit");
  const [acceptFallback, setAcceptFallback] = useState(false);
  const [setAsDefault, setSetAsDefault] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [CPF, setCpf] = useState("");
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardholderName: "",
    CPF: "",
  });
  const [sessionId, setSessionId] = useState<string | null>(null);
  // const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null); // TODO: Implement integration with reCAPTCHA

  // Retrieve the PagSeguro session ID
  useEffect(() => {
    fetch("http://localhost:5000/api/pagseguro-session")
      .then(response => response.json())
      .then(data => {
        if (data.sessionId) {
          setSessionId(data.sessionId);
          PagSeguroDirectPayment.setSessionId(data.sessionId);
        }
      })
      .catch(console.error);
  }, []);

  const validateField = (fieldName: string, value: string) => {
    let errorMessage = "";

    if (!value) {
      errorMessage = t("fieldIsRequired");
    } else {
      switch (fieldName) {
        case "cardNumber":
          if (value.replace(/\s/g, "").length < 16) {
            errorMessage = t("invalidCardNumber");
          }
          break;
        case "expiryDate":
          if (!/^\d{2}\/\d{2}$/.test(value)) {
            errorMessage = t("invalidExpiryDate");
          }
          break;
        case "cvv":
          if (value.length < 3) {
            errorMessage = t("invalidCVV");
          }
          break;
        case "CPF":
          if (value.replace(/\D/g, "").length < 11) {
            errorMessage = t("invalidCPF");
          }
          break;
        default:
          break;
      }
    }

    setErrors(prevErrors => ({ ...prevErrors, [fieldName]: errorMessage }));
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    maxLength: number,
    numericOnly: boolean,
  ) => {
    const value = numericOnly
      ? e.target.value.replace(/\D/g, "")
      : e.target.value;
    const sanitizedValue = value.slice(0, maxLength);
    if (fieldName) validateField(fieldName, sanitizedValue);
    return sanitizedValue;
  };

  const handleBlur = (fieldName: string, value: string) => {
    validateField(fieldName, value);
  };


  const handlePayment = async () => {
    const formIsValid =
      Object.values(errors).every(error => error === "") &&
      cardNumber &&
      expiryDate &&
      cvv &&
      cardholderName;

    if (!formIsValid) {
      console.error(t("formHasErrors"));
      return;
    }

    if (!sessionId) {
      console.error(t("Session ID not set. Please try again."));
      return;
    }

    // TODO: Validate reCAPTCHA
    // if (!recaptchaToken) {
    //   console.error(t("pleaseCompleteRecaptcha"));
    //   return;
    // }

    console.log("Form validated, ready to be sent to the backend");
    // TODO: Send form data to the backend
  };

  return (
    <Box sx={{ display: "flex", width: "100vw", overflowX: "hidden" }}>
      <SideMenu onChangeLayoutMode={() => {}} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          minHeight: "100vh",
          position: "relative",
          padding: isMobile ? "0.5rem" : "2rem",
        }}
      >
        <BackButton />

        <Paper
          sx={{
            width: "100%",
            maxWidth: isMobile ? "95%" : "600px",
            padding: isMobile ? "1rem" : "2rem",
            borderRadius: "1rem",
            backgroundColor: "#fff",
            color: "#3A2771",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            maxHeight: isMobile ? "70vh" : "90vh",
            overflowY: "auto",
          }}
          elevation={3}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "#414042",
              mb: "1rem",
              fontSize: isMobile ? "1.2rem" : "1.8rem",
            }}
          >
            {t("confirmPayment")}
          </Typography>

          <Box
            sx={{ display: "flex", justifyContent: "flex-start", mb: "1rem" }}
          >
            <img
              src={CreditCards}
              alt={t("creditCardImageAlt")}
              style={{ width: "100%", maxWidth: "150px" }}
            />
          </Box>

          <InputMask
            mask="9999 9999 9999 9999"
            value={cardNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCardNumber(e.target.value)
            }
          >
            {(inputProps: any) => (
              <TextField
                {...inputProps}
                label={t("cardNumber")}
                variant="outlined"
                fullWidth
                error={!!errors.cardNumber}
                helperText={errors.cardNumber}
                sx={{ mb: 2 }}
              />
            )}
          </InputMask>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
              width: "100%",
            }}
          >
            <InputMask
              mask="99/99"
              value={expiryDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setExpiryDate(handleInputChange(e, "expiryDate", 5, true))
              }
              onBlur={() => handleBlur("expiryDate", expiryDate)}
            >
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  label={t("expiryMonth")}
                  variant="outlined"
                  fullWidth
                />
              )}
            </InputMask>

            <InputMask
              mask="999"
              value={cvv}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCvv(handleInputChange(e, "cvv", 3, true))
              }
              onBlur={() => handleBlur("cvv", cvv)}
            >
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  label={t("cvv")}
                  variant="outlined"
                  fullWidth
                />
              )}
            </InputMask>
          </Box>

          <TextField
            label={t("cardholderName")}
            variant="outlined"
            fullWidth
            value={cardholderName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCardholderName(e.target.value)
            }
            sx={{ mt: 2, mb: 2 }}
          />

          <InputMask
            mask="999.999.999-99"
            value={CPF}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCpf(handleInputChange(e, "CPF", 14, true))
            }
          >
            {(inputProps: any) => (
              <TextField
                {...inputProps}
                label={t("CPF")}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
            )}
          </InputMask>

          <FormControl component="fieldset">
            <RadioGroup
              row
              value={cardType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCardType(e.target.value)
              }
            >
              <FormControlLabel
                value="credit"
                control={
                  <Radio
                    sx={{
                      color: "#B0B0B0",
                      "&.Mui-checked": { color: "#36276F" },
                    }}
                  />
                }
                label={t("creditOption")}
                sx={{ color: "#414042" }}
              />
              <FormControlLabel
                value="debit"
                control={
                  <Radio
                    sx={{
                      color: "#B0B0B0",
                      "&.Mui-checked": { color: "#36276F" },
                    }}
                  />
                }
                label={t("debitOption")}
                sx={{ color: "#414042" }}
              />
            </RadioGroup>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={acceptFallback}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAcceptFallback(e.target.checked)
                }
                sx={{
                  color: "#4140421A",
                  "&.Mui-checked": { color: "#36276F" },
                }}
              />
            }
            label={t("acceptFallback")}
            sx={{
              color: "#7d7d7d",
              fontSize: "0.675rem",
              fontWeight: 300,
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={setAsDefault}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSetAsDefault(e.target.checked)
                }
                sx={{
                  color: "#4140421A",
                  "&.Mui-checked": { color: "#36276F" },
                }}
              />
            }
            label={t("setAsDefault")}
            sx={{
              color: "#7d7d7d",
              fontSize: "0.675rem",
              fontWeight: 300,
            }}
          />

          {/*
          // TODO: Implement custom reCAPTCHA validation to protect against bots
          <Box sx={{ mt: 2, mb: 2, alignSelf: "center" }}>
            <ReCAPTCHA
              sitekey="YOUR_RECAPTCHA_SITE_KEY"
              onChange={handleRecaptchaChange}
            />
          </Box>
          */}

          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 3,
              backgroundColor: "#4C3699",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "0.5rem",
            }}
            onClick={handlePayment}
          >
            {t("confirm")}
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default PaymentDetailsForm;
