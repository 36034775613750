import { useState } from "react";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

const ffmpeg = new FFmpeg();

const convertAudioToOpus = async (audioBlob: Blob) => {
  if (!ffmpeg.loaded) {
    console.log("Loading FFmpeg...");
    await ffmpeg.load();
  }

  console.log("Converting audio to OPUS...");

  const inputFileName = "input.mp3";
  const outputFileName = "output.opus";

  await ffmpeg.writeFile(inputFileName, await fetchFile(audioBlob));

  await ffmpeg.exec([
    "-i", inputFileName,
    "-c:a", "libopus",
    "-b:a", "64k",
    outputFileName,
  ]);

  const outputData = await ffmpeg.readFile(outputFileName);

  const convertedBlob = new Blob([outputData], { type: "audio/ogg" });

  console.log("Conversion completed:", convertedBlob);

  return convertedBlob;
};

export default convertAudioToOpus;
