import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Container as MuiContainer,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../constants";
import Cookies from "js-cookie";

interface UpdateChatHistoryModalProps {
  isOpen: boolean;
  toggle: () => void;
  sessionName: string | null;
}

export const UpdateChatHistoryModal: React.FC<UpdateChatHistoryModalProps> = ({
                                                                                isOpen,
                                                                                toggle,
                                                                                sessionName,
                                                                              }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const apiKeyCookie = Cookies.get("api-key");

  const handleUpdateChatHistory = async () => {
    if (!sessionName) return;

    console.log("🟢 Modal aberta para a sessão:", sessionName);

    setLoading(true);
    try {
      console.log("🚀 Atualizando histórico de chat para a sessão:", sessionName);

      const requestBody = {
        sessionName: sessionName,
        chatLimit: 50,
        chatOffset: 0,
        limit: 5,
        downloadMedia: "false",
      };

      const response = await fetch(`${URLS.CLIENT_URL}/api/start-fetch-chats`, {
        method: "POST",
        headers: {
          APIKEY: apiKeyCookie || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Erro ao atualizar histórico.");
      }

      toast.success(t("Histórico atualizado com sucesso!"));
      toggle(); // Fecha a modal após sucesso
    } catch (error) {
      console.error("❌ Erro ao atualizar histórico:", error);
      toast.error(t("Falha ao atualizar histórico."));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
      <DialogTitle>{t("Atualizar Histórico de Chat")}</DialogTitle>
      <DialogContent dividers>
        <MuiContainer
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {t("Deseja atualizar o histórico de chat para esta sessão?")}
          </Typography>
        </MuiContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} color="secondary" disabled={loading}>
          {t("Cancelar")}
        </Button>
        <Button onClick={handleUpdateChatHistory} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : t("Atualizar")}
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};
