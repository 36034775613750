import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Box,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import ChatList from "./ChatList";
import Archive from "./Archive";
import AppSimpleBar from "../../../components/AppSimpleBar";
import InviteContactModal from "../../../components/InviteContactModal";
import ContactModal from "../../../components/ContactModal";
import ChatMessages from "../ConversationUser/index";

import {
  archiveChats, UserTypes
} from "../../../data";
import { CHATS_TABS, STATUS_TYPES, URLS } from "../../../constants";

interface MediaItem {
  type: "image" | "document" | "audio";
  base64Data: string;
  url: string;
  mimetype?: string;
  filename?: string;
}

interface WebSocketMessage {
  id: string;
  body: string;
  fromMe: boolean;
  channel: string;
  session: string;
  timestamp: number;
  shouldReply?: boolean;
  from: string;
  to?: string;
  hasMedia?: boolean;
  hasAudio?: boolean;
  media?: MediaItem[] | null;
}

type MessageType = {
  mId: string;
  text: string;
  fromMe: boolean;
  channel: string;
  session: string;
  time: string;
  attachments: { name: string; downloadLink: string; base64Data: string; mimetype: string }[];
  replyOf?: { text: string; id: string };
  meta: {
    sender: string;
    receiver: string;
    sent: boolean;
    received: boolean;
    read: boolean;
  };
};

const Index = () => {
  const { t } = useTranslation();
  const [selectedChat, setSelectedChat] = useState<number | string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  const [active, setActive] = useState(CHATS_TABS.DEFAULT);
  const [showChatList, setShowChatList] = useState(true);
  const [chatList, setChatList] = useState<UserTypes[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useMediaQuery("(max-width:900px)");
  const [chatHeight, setChatHeight] = useState("calc(100vh - 10px)");
  const ws = useRef<WebSocket | null>(null);
  const [conversationMap, setConversationMap] = useState<Record<string, any>>({});

  console.log("Chats: ", conversationMap);

  useEffect(() => {
    const updateChatHeight = () => {
      if (window.innerWidth <= 900) {
        setChatHeight("calc(100vh - 110px)");
      } else {
        setChatHeight("calc(100vh - 10px)");
      }
    };

    window.addEventListener("resize", updateChatHeight);
    updateChatHeight();

    return () => window.removeEventListener("resize", updateChatHeight);
  }, []);

  useEffect(() => {
    const company = Cookies.get("companyName");
    const wsURL = `${URLS.WEBSOCKET_URL}/api/ws/web_client/messages?company=${company}`;
    console.log("Conectando ao WebSocket:", wsURL);

    ws.current = new WebSocket(wsURL);

    ws.current.onopen = () => {
      console.log("Conexão WebSocket estabelecida");
    };

    ws.current.onmessage = (event) => {
      try {
        const payload = JSON.parse(event.data);
        console.log("Dados recebidos via WebSocket:", payload);

        const chat = payload.data;

        const mappedChat = {
          id: chat.id,
          firstName: chat.name || "",
          whatsappPhoneId: chat.whatsapp_phone_id,
          whatsappPhoneNumber: chat.whatsapp_phone_number,
          whatsappProfilePic: chat.whatsapp_profile_pic,
          lastName: "",
          profileImage: chat.profile,
          status: STATUS_TYPES.ACTIVE,
          email: chat.email || "",
          location: chat.location || "",
          timestamp: chat.timestamp,
          meta: {
            unRead: 0,
            messagePreview: chat.lastMessage || "",
            lastMessageTime: new Date(chat.timestamp * 1000).toLocaleString(),
            lastMessageSent: chat.lastMessageSent || false,
            lastMessageRead: chat.lastMessageRead || false,
          },
        };

        console.log("chatList before setChatList:", chatList) //TODO: REMOVE THIS

        setChatList((prevChats) => {
          const updatedChats = new Map(prevChats.map((c) => [c.id, c]));

          updatedChats.set(mappedChat.id, mappedChat);

          const sortedChats = Array.from(updatedChats.values()).sort((a, b) => {
            const timeA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
            const timeB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
            return timeB - timeA;
          });

          console.log("updatedChats (inside setChatList): ", sortedChats); //TODO: REMOVE THIS

          return sortedChats;
        });


        console.log("chatList after setChatList:", chatList) //TODO: REMOVE THIS

        if (chat.messages?.length) {
          setConversationMap((prevConversations) => {
            const existingMessages = prevConversations[chat.id] || [];

            const newMessages = chat.messages.map((msg: WebSocketMessage): MessageType => {
              // Garante que `msg.media` é sempre um array válido
              const mediaArray: MediaItem[] = msg.media
                ? Array.isArray(msg.media)
                  ? msg.media
                  : [msg.media]
                : [];

              console.log("msg.media: ", msg.media);
              console.log("mediaArray: ", mediaArray);

              return {
                mId: msg.id,
                text: msg.body,
                fromMe: msg.fromMe,
                session: msg.session,
                channel: msg.channel,
                time: new Date(msg.timestamp * 1000).toLocaleString(),
                attachments: mediaArray.map((m: MediaItem) => ({
                  name:
                    m.filename ||
                    (m.mimetype?.startsWith("image/")
                      ? `image-${Date.now()}.${m.mimetype.split("/").pop()}`
                      : m.mimetype?.startsWith("audio/")
                        ? `audio-${Date.now()}.${m.mimetype.split("/").pop()}`
                        : m.mimetype?.startsWith("application/")
                          ? `document-${Date.now()}.${m.mimetype.split("/").pop()}`
                          : `file-${Date.now()}.${m.mimetype?.split("/").pop() || "unknown"}`),
                  base64Data: m.base64Data,
                  downloadLink: m.url,
                  mimetype: m.mimetype || "unknown",
                })),
                replyOf: msg.shouldReply
                  ? {
                    text: msg.body || "[Mídia]",
                    id: msg.id,
                  }
                  : undefined,
                meta: {
                  sender: msg.fromMe ? "You" : msg.from,
                  receiver: msg.fromMe ? chat.id : msg.to || "You",
                  sent: msg.fromMe,
                  received: !msg.fromMe,
                  read: true,
                },
              };
            });

            const messageSet = new Map(existingMessages.map((m: MessageType) => [m.mId, m]));
            newMessages.forEach((m: MessageType) => messageSet.set(m.mId, m));

            return {
              ...prevConversations,
              [chat.id]: Array.from(messageSet.values()),
            };
          });
        }
      } catch (error) {
        console.error("Erro ao processar mensagem do WebSocket:", error);
      }
    };

    ws.current.onclose = () => {
      console.log("Conexão WebSocket fechada");
    };

    ws.current.onerror = (error) => {
      console.error("Erro no WebSocket:", error);
    };

    return () => {
      ws.current?.close();
    };
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const openAddContactModal = () => setIsOpenAddContact(true);
  const closeAddContactModal = () => setIsOpenAddContact(false);

  const onChangeTab = (tab: CHATS_TABS) => {
    setActive(tab);
  };

  const onSelectChat = (id: number | string) => {
    setSelectedChat(id);
    if (isMobile) {
      setShowChatList(false);
    }
  };

  const handleBackToChatList = () => {
    setShowChatList(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredChatList = chatList
    .sort((a, b) => {
      const timeA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
      const timeB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
      return timeB - timeA;
    })
    .filter(contact => {
      const fullName = `${contact.firstName} ${contact.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });

  console.log("filteredChatList", filteredChatList);

  const selectedContact = chatList.find(contact => contact.id === selectedChat);

  return (
    <Grid container height="100vh">
      {/* Sidebar Chats */}
      {showChatList || !isMobile ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          sx={{ backgroundColor: "#f7f7f7" }}
        >
          <Box p={3} display="flex" flexDirection="column" height="100%">
            <Typography variant="h5" mb={2}>
              {t("Chats")}
            </Typography>
            <TextField
              fullWidth
              onChange={handleSearch}
              id="searchChatUser"
              placeholder={t("search_messages_or_users")}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <AppSimpleBar className="chat-room-list" style={{ flexGrow: 1 }}>
              {active === CHATS_TABS.DEFAULT && (
                <>
                  <ChatList
                    users={filteredChatList}
                    selectedChat={selectedChat}
                    onSelectChat={onSelectChat}
                  />

                  <Typography
                    align="center"
                    variant="body2"
                    component="a"
                    href="#"
                    onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      mt: 2,
                      display: "block",
                    }}
                  >
                    {t("archived_contacts")}
                  </Typography>
                </>
              )}

              {active === CHATS_TABS.ARCHIVE && (
                <>
                  <Archive
                    archiveContacts={archiveChats}
                    selectedChat={selectedChat}
                    onSelectChat={onSelectChat}
                  />
                  <Typography
                    align="center"
                    variant="body2"
                    component="a"
                    href="#"
                    onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}
                    sx={{
                      textDecoration: "none",
                      color: "primary.main",
                      mt: 2,
                      display: "block",
                    }}
                  >
                    {t("chats")}
                  </Typography>
                </>
              )}
            </AppSimpleBar>
          </Box>
        </Grid>
      ) : null}

      {!showChatList || !isMobile ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={9}
          sx={{ backgroundColor: "#fff" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {isMobile && (
              <IconButton onClick={handleBackToChatList}>
                <ArrowBackIcon />
              </IconButton>
            )}
            {selectedContact ? (
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                overflow="hidden"
              >
                <Box
                  id="chat-conversation-list"
                  sx={{
                    flexGrow: 1,
                    maxHeight: chatHeight,
                    overflowY: "auto",
                  }}
                >
                  <ChatMessages
                    isChannel={false}
                    contact={selectedContact}
                    conversation={
                      {
                        conversationId: String(selectedContact.id),
                        userId: String(selectedContact.id),
                        messages: conversationMap[selectedChat],
                      }
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography variant="h6">{t("no_chat_selected")}</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      ) : null}

      {isOpen && (
        <InviteContactModal
          isOpen={isOpen}
          onClose={closeModal}
          onInvite={data => console.log("Contato convidado: ", data)}
        />
      )}

      {isOpenAddContact && (
        <ContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onAddContact={contacts =>
            console.log("Contato adicionado: ", contacts)
          }
        />
      )}
    </Grid>
  );
};

export default Index;
