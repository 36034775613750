import React, { useState } from "react";
import SideMenu from "../../layouts/Default/SideMenu";
import {
  Box,
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Grid,
  TextField,
  useMediaQuery,
  Divider,
  Modal,
} from "@mui/material";
import CancelIcon from "../../assets/images/new-icons/cancel-icon.svg";
import BackButton from "../../components/BackButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CancellationSuccessScreen from "../Payment/CancelConfirmation";

const CancelSubscriptionScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [selectedOption, setSelectedOption] = useState("notSure");
  const [feedback, setFeedback] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleConfirmClick = () => {
    setShowSuccessModal(true);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100vw", overflowX: "hidden" }}>
        <SideMenu onChangeLayoutMode={() => {}} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            minHeight: "100vh",
            position: "relative",
            padding: isMobile ? "0.5rem" : "2rem",
          }}
        >
          <BackButton />

          <Paper
            sx={{
              width: "100%",
              maxWidth: isMobile ? "85%" : "40rem",
              padding: isMobile ? "1rem" : "2rem",
              borderRadius: "1.2rem",
              backgroundColor: "#fff",
              color: "#3A2771",
              boxShadow: "0rem 0.75rem 1.5rem rgba(0, 0, 0, 0.1)",
              zIndex: 2,
              position: "relative",
              maxHeight: isMobile ? "75vh" : "auto",
              overflowY: "auto",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            elevation={4}
          >
            {/* Header */}
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={CancelIcon}
                    alt="Cancel Icon"
                    width={isMobile ? 26 : 42}
                    height={isMobile ? 26 : 42}
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography
                  variant={isMobile ? "h6" : "h5"}
                  sx={{
                    fontWeight: "700",
                    color: "#4C3699",
                    fontSize: isMobile ? "1.2rem" : "2rem",
                    textAlign: "center",
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                >
                  {t("cancellation")}
                </Typography>
              </Grid>
            </Grid>

            <Divider
              sx={{
                backgroundColor: "#414042",
                height: "0.1rem",
                my: "0.7rem",
              }}
            />

            {/* Content */}
            <Typography
              variant="body2"
              sx={{
                color: "#414042",
                fontSize: isMobile ? "0.85rem" : "1rem",
                fontWeight: "300",
                mt: "0.5rem",
                textAlign: "start",
                overflow: "hidden",
              }}
            >
              {t("cancelConfirmation")}
            </Typography>

            <RadioGroup
              value={selectedOption}
              onChange={e => setSelectedOption(e.target.value)}
              sx={{ mt: "0.7rem" }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label={t("yes")}
                sx={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "8px",
                  padding: "0.6rem",
                  mb: "0.4rem",
                  width: "100%",
                  cursor: "pointer",
                  transition: "background-color 0.2s",
                  "&:hover": { backgroundColor: "#EAEAEA" },
                  "& .MuiRadio-root": {
                    color: "#4C3699",
                    "&.Mui-checked": { color: "#4C3699" },
                  },
                }}
              />
              {selectedOption === "yes" && (
                <Box
                  sx={{
                    position: "relative",
                    my: "0.7rem",
                    mx: "0.6rem",
                    right: "3%!important",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#414042",
                      fontWeight: "300",
                      mb: "0.5rem",
                      fontSize: isMobile ? "0.85rem" : "1rem",
                    }}
                  >
                    {t("feedbackRequest")}
                  </Typography>
                  <TextField
                    placeholder={t("typeHere")}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    sx={{
                      backgroundColor: "#F6F6F6",
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "transparent" },
                        "&:hover fieldset": { borderColor: "#4C3699" },
                        "&.Mui-focused fieldset": { borderColor: "#4C3699" },
                      },
                    }}
                  />
                </Box>
              )}

              <FormControlLabel
                value="notSure"
                control={<Radio />}
                label={t("notSure")}
                sx={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: "8px",
                  padding: "0.6rem",
                  width: "100%",
                  cursor: "pointer",
                  transition: "background-color 0.2s",
                  "&:hover": { backgroundColor: "#EAEAEA" },
                  "& .MuiRadio-root": {
                    color: "#4C3699",
                    "&.Mui-checked": { color: "#4C3699" },
                  },
                }}
              />
            </RadioGroup>

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "1rem" }}
            >
              <Button
                variant="contained"
                onClick={handleConfirmClick}
                sx={{
                  backgroundColor: "#382772",
                  color: "#fff",
                  paddingX: "1rem",
                  paddingY: "0.3rem",
                  borderRadius: "8px",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#4C3699" },
                }}
              >
                {t("confirm")}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* MODAL DE SUCESSO */}
      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CancellationSuccessScreen onClose={() => setShowSuccessModal(false)} />
      </Modal>
    </>
  );
};

export default CancelSubscriptionScreen;
