import React, { useState } from "react";
import SideMenu from "../../layouts/Default/SideMenu";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChangePlanIcon from "../../assets/images/new-icons/change-plans-icon.svg";
import BackButton from "../../components/BackButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type PlanKey = "free-tier" | "basic-tier" | "premium-tier" | "gold-tier";

interface PlanMapping {
  label: string;
  details: string;
  price: string;
}

const planMapping: Record<PlanKey, PlanMapping> = {
  "free-tier": {
    label: "free-tier",
    details: "DetailsBasicTier",
    price: "price.Free Tier",
  },
  "basic-tier": {
    label: "basic-tier",
    details: "DetailsPremiumTier",
    price: "price.Basic Tier",
  },
  "premium-tier": {
    label: "premium-tier",
    details: "DetailsGoldTier",
    price: "price.Premium Tier",
  },
  "gold-tier": {
    label: "gold-tier",
    details: "DetailsOnDemand",
    price: "price.Gold Tier",
  },
};

const plans = [
  { id: "free-tier" as PlanKey, label: "Plano Gratuito" },
  { id: "basic-tier" as PlanKey, label: "Plano Básico" },
  { id: "premium-tier" as PlanKey, label: "Plano Premium" },
  { id: "gold-tier" as PlanKey, label: "Plano Gold" },
];

const PlansScreen: React.FC = () => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState<PlanKey | null>(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ display: "flex", width: "100vw", overflowX: "hidden" }}>
      <SideMenu onChangeLayoutMode={() => {}} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          minHeight: "100vh",
          position: "relative",
          padding: isMobile ? "1rem" : "2rem",
        }}
      >
        <BackButton />

        <Paper
          sx={{
            width: "100%",
            maxWidth: "40rem",
            padding: isMobile ? "1rem" : "2.5rem",
            borderRadius: "1.5rem",
            backgroundColor: "#fff",
            color: "#3A2771",
            boxShadow: "0rem 0.75rem 1.5rem rgba(0, 0, 0, 0.1)",
            zIndex: 2,
            maxHeight: isMobile ? "70vh" : "90vh",
            overflowY: "auto",
          }}
          elevation={4}
        >
          <Grid
            container
            alignItems="center"
            spacing={1}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <Grid item component="div">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <img
                  src={ChangePlanIcon}
                  alt="Change subscription plan"
                  width={isMobile ? 32 : 42}
                  height={isMobile ? 32 : 42}
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item component="div">
              <Typography
                variant={isMobile ? "h6" : "h5"}
                sx={{
                  fontWeight: "700",
                  color: "#4C3699",
                  fontSize: isMobile ? "1.5rem" : "2.1rem",
                  textAlign: "center",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                {t("changePlans")}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{ backgroundColor: "#414042", height: "0.1rem", my: "1rem" }}
          />

          {!selectedPlan ? (
            <>
              <Typography
                variant="body2"
                sx={{
                  color: "#414042",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  fontWeight: "300",
                  mt: "0.5rem",
                  textAlign: "center",
                  overflow: "hidden",
                }}
              >
                {t("planDescription")}
              </Typography>

              <List sx={{ backgroundColor: "#fff", padding: 0, mt: 2 }}>
                {plans.map(plan => (
                  <ListItem
                    key={plan.id}
                    onClick={() => setSelectedPlan(plan.id)}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "10px",
                      padding: isMobile ? "0.8rem" : "1rem",
                      transition: "background-color 0.2s",
                      mb: isMobile ? 1 : 1.5,
                      "&:hover": { backgroundColor: "#EAEAEA" },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxWidth: "100%",
                    }}
                  >
                    <ListItemText
                      primary={t(planMapping[plan.id].label)}
                      sx={{
                        fontSize: isMobile ? "0.95rem" : "1rem",
                        fontWeight: 500,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    />
                    <ArrowForwardIosIcon
                      sx={{
                        color: "#414042",
                        fontSize: isMobile ? "0.9rem" : "1rem",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <Box>
              <Button
                onClick={() => setSelectedPlan(null)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#4C3699",
                  mb: 2,
                  fontSize: isMobile ? "0.8rem" : "0.875rem",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "transparent" },
                }}
              >
                <ArrowBackIosNewIcon fontSize="small" sx={{ mr: 1 }} />
                {t("backToList")}
              </Button>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                }}
              >
                {t(planMapping[selectedPlan].label)}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#4CAF50",
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  mt: 1,
                }}
              >
                {t(planMapping[selectedPlan].price) || "Preço não disponível"}
              </Typography>

              <Box
                sx={{
                  maxHeight: isMobile ? "200px" : "250px",
                  overflowY: "auto",
                  padding: "0.5rem",
                  borderRadius: "8px",
                  mt: 2,
                  backgroundColor: "#F9F9F9",
                }}
              >
                {t(planMapping[selectedPlan].details)
                  .split("\n")
                  .map((line, index) => (
                    <Typography key={index} variant="body2">
                      <CheckCircleIcon sx={{ color: "#4CAF50", mr: 1 }} />
                      {line}
                    </Typography>
                  ))}
              </Box>

              <Box sx={{ textAlign: "right", mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#4C3699",
                    color: "#fff",
                    padding: "0.5rem 1.5rem",
                    fontSize: "1rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {t("confirm")}
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default PlansScreen;
