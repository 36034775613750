import React from "react";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "../../assets/images/new-icons/check-icon.png";
import { useTranslation } from "react-i18next";

interface CancellationSuccessScreenProps {
  onClose?: () => void;
}

const CancellationSuccessScreen: React.FC<CancellationSuccessScreenProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(131, 131, 131, 0.16)",
        backdropFilter: "blur(12px)",
        zIndex: 10,
      }}
      onClick={onClose}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "rgba(134, 110, 159, 0.1)",
          backdropFilter: "blur(15px)",
          padding: "2.5rem",
          borderRadius: "1.5rem",
          textAlign: "center",
          color: "#fff",
          width: "90%",
          maxWidth: "46rem",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          position: "relative",
        }}
        onClick={e => e.stopPropagation()}
      >
        {onClose && (
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              color: "#fff",
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" },
              borderRadius: "50%",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <img
          src={CheckCircleIcon}
          alt="check circle icon"
          style={{ width: "64px", height: "64px", marginBottom: "1rem" }}
        />

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mt: "2rem",
            color: "#fff",
            fontSize: "1.4rem",
          }}
        >
          {t("cancellationSuccess")}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: "#E1E1E1",
            maxWidth: "30rem",
            mt: "0.75rem",
          }}
        >
          {t("pauseAndReturn")}
        </Typography>
      </Paper>
    </Box>
  );
};

export default CancellationSuccessScreen;
