import React, { useState } from "react";
import { Box, Avatar, Typography, IconButton, Tooltip } from "@mui/material";
import {
  Search as SearchIcon,
  Phone as PhoneIcon,
  VideoCall as VideoCallIcon,
  Info as InfoIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { STATUS_TYPES } from "../../../constants";
import { PinTypes } from "../../../data/chat";

interface UserHeadProps {
  chatUserDetails: any;
  pinnedTabs: PinTypes[];
  onOpenUserDetails: () => void;
  onDelete: () => void;
  isChannel: boolean;
  onToggleArchive: () => void;
}

const UserHead = ({
  chatUserDetails,
  pinnedTabs,
  onOpenUserDetails,
  onDelete,
  isChannel,
  onToggleArchive,
}: UserHeadProps) => {
  const { t } = useTranslation();

  const extractNumberFromId = (id: string | number) => {
    const stringId = String(id);
    const atIndex = stringId.indexOf("@");
    return atIndex > -1 ? stringId.substring(0, atIndex) : stringId;
  };

  const fullName = !isChannel
    ? chatUserDetails.firstName || chatUserDetails.lastName
      ? `${chatUserDetails.firstName || ""} ${chatUserDetails.lastName || ""}`.trim()
      : extractNumberFromId(chatUserDetails.id)
    : chatUserDetails.name;

  const shortName = !isChannel
    ? chatUserDetails.firstName || chatUserDetails.lastName
      ? `${chatUserDetails.firstName?.charAt(0) || ""}${chatUserDetails.lastName?.charAt(0) || ""}`
      : extractNumberFromId(chatUserDetails.id).substring(0, 2)
    : "#";

  const colors = [
    "#1976d2",
    "#d32f2f",
    "#0288d1",
    "#fbc02d",
    "#616161",
    "#e91e63",
    "#9c27b0",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  const isOnline =
    chatUserDetails.status && chatUserDetails.status === STATUS_TYPES.ACTIVE;

  const members = (chatUserDetails.members || []).length;

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #e0e0e0"
    >
      <Box display="flex" alignItems="center">
        <Avatar src={chatUserDetails.profileImage} alt={fullName} />
        <Box ml={2}>
          <Typography variant="h6" noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {isChannel
              ? `${chatUserDetails.members.length} Members`
              : chatUserDetails.status}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {/* <Tooltip title={t("Audio Call")} arrow>
          <IconButton>
            <PhoneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Video Call")} arrow>
          <IconButton>
            <VideoCallIcon />
          </IconButton>
        </Tooltip> */}
        {/* <Tooltip title={t("User Info")} arrow>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("More Options")} arrow>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Box>
  );
};

export default UserHead;
