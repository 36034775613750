import React from "react";
import SideMenu from '../../layouts/Default/SideMenu';
import { Box, Typography, Button, Grid } from "@mui/material";
import PaymentConfirmationImage from "../../assets/images/paymentConfirm.svg";
import { useTranslation } from 'react-i18next';

interface PaymentConfirmationProps {
  details?: any;
}

const PaymentConfirmation = ({ details }: PaymentConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* Side Menu */}
      <SideMenu
        onChangeLayoutMode={() => {}}
      />

      {/*  Principal */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          height: "100vh",
          backgroundColor: "#4C3699",
          backgroundImage: "linear-gradient(135deg, #4C3699 30%, #6F42C1 100%)",
          padding: 2,
          overflow: "hidden",
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            maxWidth: { xs: '100%', md: '900px' },
            backgroundColor: "transparent",
            padding: { xs: 2, md: 4 },
            borderRadius: 4,
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "#92EF8F", fontSize: { xs: '1.8rem', sm: '2.5rem' } }}
            >
              {t('paymentConfirmed')}
            </Typography>
            <Typography variant="body1" sx={{ color: "#ddd", mb: 4, fontSize: { xs: '1rem', sm: '1.1rem' } }}>
              {t('paymentSuccess')}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#fff",
                color: "#4C3699",
                fontWeight: "bold",
                borderRadius: 2,
                padding: "10px 20px",
                fontSize: { xs: '0.9rem', sm: '1rem' },
              }}
            >
              {t('backToHome')}
            </Button>
          </Grid>

          {/* Right column: Confirmation image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={PaymentConfirmationImage}
              alt={t('paymentIllustration')}
              style={{ width: '90%', maxWidth: '300px' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PaymentConfirmation;
