import React from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import jessIcon from "../../../assets/images/jess.png";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  Grid,
  Avatar,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Typewriter } from "react-simple-typewriter";
import { STATUS } from "../../../constants";

interface StatusPageProps {
  jessPhoneNumber: string;
  statusJess: STATUS;
}

const JessPage: React.FC<StatusPageProps> = ({
  jessPhoneNumber,
  statusJess,
}) => {
  const { t } = useTranslation();

  const formatPhoneNumber = (phoneNumber: string): string => {
    const countryCode = "55";
    const numberWithoutCountryCode = phoneNumber.slice(2);
    const areaCode = numberWithoutCountryCode.slice(0, 2);
    const mainNumber = numberWithoutCountryCode.slice(2);

    const formattedNumber =
      mainNumber.length === 8
        ? `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`
        : `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;

    return `+${countryCode} ${areaCode} ${formattedNumber}`;
  };

  return (
    <Container maxWidth="xl">
      {" "}
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {" "}
          <Card
            sx={{
              width: "100%",
              maxWidth: "none",
              boxShadow: "none",
              borderBottom: "1px solid rgba(65, 64, 66, 0.81)",
              mb: 2,
              pb: 1,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              {/* Profile picture */}
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  border:
                    statusJess === "Online"
                      ? "2px solid #92EF8F"
                      : "2px solid #dc3545",
                  mr: 2,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={jessIcon}
                  alt="WhatsApp Icon"
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>

              {/* Text information */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: { xs: "center", lg: "start" },
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    color: "#4C3699",
                    margin: 0,
                    fontSize: 34,
                    fontWeight: "bold",
                  }}
                >
                  Jess
                </Typography>
                <Typography sx={{ fontSize: 14, color: "#414042", mb: 1 }}>
                  {statusJess === STATUS.Online && (
                    <span style={{ color: "#06d6a0" }}>Online</span>
                  )}
                  {statusJess === STATUS.Offline && (
                    <span style={{ color: "#dc3545" }}>Offline</span>
                  )}
                  {statusJess === STATUS.ConectWhatsapp && (
                    <span style={{ color: "#e60000" }}>
                      {t("connect_whatsapp")}
                    </span>
                  )}
                  {statusJess === STATUS.OpenAgent && (
                    <span style={{ color: "#e76f51" }}>{t("open_agent")}</span>
                  )}
                </Typography>
                {jessPhoneNumber ? (
                  <Typography
                    sx={{
                      fontSize: 22,
                      color: "#414042",
                      textDecoration: "none",
                      margin: 0,
                    }}
                  >
                    {formatPhoneNumber(jessPhoneNumber)}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: 20, color: "#6c757d" }}>
                    {t("message_phone_unavailable")}
                  </Typography>
                )}
              </Box>

              {statusJess === "Online" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* Typing animated */}
                  <Box
                    sx={{
                      padding: "15px 20px",
                      textAlign: "center",
                      width: "20em",
                    }}
                  >
                    <Typewriter
                      words={[
                        t("how_can_i_help_you"),
                        t("customize_communication"),
                        t("tone_of_voice"),
                        t("language_style"),
                        t("essential_information"),
                        t("information_to_avoid"),
                        t("customized_communication_guide"),
                      ]}
                      loop={true}
                      cursor
                      cursorStyle="_"
                      typeSpeed={70}
                      deleteSpeed={10}
                      delaySpeed={3000}
                    />
                  </Box>

                  {/* Action Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href={`https://wa.me/${jessPhoneNumber.replace(/\D/g, "")}`}
                    sx={{ background: "#4e36a0" }}
                  >
                    {t("give_instructions_to_jess")}
                    <FaExternalLinkAlt
                      style={{ marginLeft: "8px", fontSize: "14px" }}
                    />
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Trechos comentados mantidos e convertidos para Material-UI */}
      {/* <Box sx={{ textAlign: "center", my: 5 }}>
           <img
             src={emptyStateImage}
             alt={t("No sessions yet")}
             style={{ width: "150px", marginBottom: "20px" }}
           />
           <Typography variant="h5" className="subtitle">
             Error
           </Typography>
         </Box> */}
    </Container>
  );
};

export default JessPage;
