import { STATUS_TYPES } from "../constants";
import { contacts } from "./contacts";
import { userChannels } from "./channels";

export interface UserTypes {
  id: string | number;
  firstName: string;
  lastName: string;
  profileImage?: any;
  status?: STATUS_TYPES;
  timestamp: string;
  meta?: {
    unRead?: number;
    status?: STATUS_TYPES;
    messagePreview?: string;
    lastMessageTime?: string;
    lastMessageSent?: boolean;
    lastMessageRead?: boolean;
  };
}

let chatList: Array<UserTypes> = [
  {
    ...contacts[4],
    timestamp: "",
    meta: {
      unRead: 3,
      status: STATUS_TYPES.ACTIVE,
      messagePreview: "Última mensagem de exemplo",
      lastMessageTime: "9:04",
      lastMessageSent: true,
      lastMessageRead: true,
    },
  },
  {
    ...contacts[5],
    timestamp: "",
    meta: {
      status: STATUS_TYPES.ACTIVE,
      messagePreview: "Outra prévia de mensagem",
      lastMessageTime: "8:45",
    },
  },
  {
    ...contacts[6],
    timestamp: "",
    meta: {
      messagePreview: "Mais uma prévia de mensagem",
      lastMessageTime: "8:30",
    },
  },
  {
    ...contacts[7],
    timestamp: "",
    meta: {
      status: STATUS_TYPES.ACTIVE,
      messagePreview: "Prévia de mensagem",
      lastMessageTime: "8:15",
    },
  },
];

let directMessages: Array<UserTypes> = [
  {
    ...contacts[16],
    timestamp: "",
    meta: {
      messagePreview: "Direta mensagem de exemplo",
      lastMessageTime: "9:00",
    },
  },
  {
    ...contacts[17],
    timestamp: "",
    meta: {
      messagePreview: "Direta outra prévia de mensagem",
      lastMessageTime: "8:50",
    },
  },
  {
    ...contacts[8],
    timestamp: "",
    meta: {
      unRead: 5,
      messagePreview: "Direta mais uma prévia de mensagem",
      lastMessageTime: "8:40",
    },
  },
  {
    ...contacts[18],
    timestamp: "",
    meta: {
      messagePreview: "Direta prévia de mensagem",
      lastMessageTime: "8:35",
    },
  },
  {
    ...contacts[3],
    timestamp: "",
    meta: {
      messagePreview: "Mensagem direta de exemplo",
      lastMessageTime: "8:20",
    },
  },
  {
    ...contacts[11],
    timestamp: "",
    meta: {
      messagePreview: "Exemplo de prévia de mensagem",
      lastMessageTime: "8:10",
    },
  },
];

const onChangeDirectMessages = (newData: Array<UserTypes>) => {
  directMessages = newData;
};

export interface ChannelTypes {
  id: number | string;
  name: string;
  meta?: {
    unRead: number;
  };
}

let channels: Array<ChannelTypes> = [
  {
    ...userChannels[0],
    meta: {
      unRead: 12,
    },
  },
  {
    ...userChannels[1],
  },
  {
    ...userChannels[2],
    meta: {
      unRead: 85,
    },
  },
  {
    ...userChannels[3],
  },
];

const onChangeChannels = (newData: Array<ChannelTypes>) => {
  channels = newData;
};

export interface PinTypes {
  id: number;
  title: string;
  desc: string;
  icon: string;
}

const pinnedTabs: Array<PinTypes> = [
  {
    id: 1,
    title: "design-phase-1-approved.pdf",
    desc: "12.5 MB",
    icon: "bx bx-file",
  },
  {
    id: 2,
    title: "Bg Pattern",
    desc: "https://bgpattern.com/",
    icon: "bx bx-pin",
  },
  {
    id: 3,
    title: "Image-001.jpg",
    desc: "4.2 MB",
    icon: "bx bx-image",
  },
  {
    id: 4,
    title: "Images",
    desc: "https://images123.com/",
    icon: "bx bx-file",
  },
  {
    id: 5,
    title: "Bg Gradient",
    desc: "https://bggradient.com/",
    icon: "bx bx-pin",
  },
  {
    id: 6,
    title: "Image-012.jpg",
    desc: "3.1 MB",
    icon: "bx bx-image",
  },
  {
    id: 7,
    title: "analytics dashboard.zip",
    desc: "6.7 MB",
    icon: "bx bx-image",
  },
  {
    id: 8,
    title: "Bg Gradient",
    desc: "https://bggradient.com/",
    icon: "bx bx-file",
  },
  {
    id: 9,
    title: "Image-012.jpg",
    desc: "3.1 MB",
    icon: "bx bx-pin",
  },
  {
    id: 10,
    title: "analytics dashboard.zip",
    desc: "6.7 MB",
    icon: "bx bx-pin",
  },
];

const archiveContacts = (contacts || []).filter((cn: any) => cn.isArchived);

let archiveChats: Array<any> = [
  ...archiveContacts,
  {
    ...userChannels[1],
    isChannel: true,
  },
];

const onChangeFavourite = (newData: Array<UserTypes>) => {
  chatList = newData;
};

const onChangeArchives = (newData: Array<any>) => {
  archiveChats = newData;
};

export {
  chatList,
  directMessages,
  channels,
  onChangeDirectMessages,
  onChangeChannels,
  onChangeFavourite,
  pinnedTabs,
  archiveChats,
  onChangeArchives,
};
