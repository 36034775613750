import React, { useState, useRef, useEffect, FC } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SendIcon from "@mui/icons-material/Send";

interface AudioRecorderProps {
  onSendAudio: (audioBlob: Blob) => void;
  onCancel: () => void;
}

const AudioRecorder: FC<AudioRecorderProps> = ({ onSendAudio, onCancel }) => {
  const [isRecording, setIsRecording] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    startRecording();
    return () => {
      if (audioURL) {
        URL.revokeObjectURL(audioURL);
      }
      clearInterval(timerRef.current!);
    };
  }, []);

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "audio/webm;codecs=opus", // Teste outros MIME types como mp3 ou wav
        });
        mediaRecorderRef.current.ondataavailable = event => {
          audioChunks.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          let mimeType;
          const audioBlob = new Blob(audioChunks.current, { type: mimeType });
          const audioURL = URL.createObjectURL(audioBlob);
          console.log("Blob gerado com URL:", audioURL);
          setAudioBlob(audioBlob);
          setAudioURL(audioURL);
          audioChunks.current = [];
        };
        mediaRecorderRef.current.start();
        startTimer();
      } catch (error) {
        console.error("Erro ao acessar o microfone:", error);
      }
    }
  };

  const startTimer = () => {
    setRecordingTime(0);
    timerRef.current = window.setInterval(() => {
      setRecordingTime(prev => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      stopTimer();
    }
  };

  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      stopTimer();
    }
  };

  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleAudioTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration || 0);
    }
  };

  const handleSendAudio = () => {
    if (audioBlob) {
      onSendAudio(audioBlob);
      resetRecorder();
    }
  };

  const resetRecorder = () => {
    setAudioURL(null);
    setAudioBlob(null);
    setIsPlaying(false);
    setRecordingTime(0);
    onCancel();
  };

  const formatTime = (seconds: number) => {
    if (isNaN(seconds) || seconds === Infinity) return "0:00"; // Fallback para valores inválidos
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
      {audioURL ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            maxWidth: "300px",
            padding: "8px",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)"
          }}
        >
          <audio
            ref={audioRef}
            src={audioURL}
            onLoadedMetadata={() => {
              if (audioRef.current) {
                const loadedDuration = audioRef.current.duration;
                console.log("Duração carregada:", loadedDuration);
                setDuration(isNaN(loadedDuration) ? 0 : loadedDuration);
              }
            }}
            onTimeUpdate={handleAudioTimeUpdate}
            onEnded={() => setIsPlaying(false)}
            hidden
          />
          <IconButton onClick={handlePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Box flex="1" display="flex" flexDirection="column">
            <Box
              sx={{
                height: "4px",
                backgroundColor: "#ddd",
                borderRadius: "2px",
                overflow: "hidden"
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: `${(currentTime / duration) * 100 || 0}%`,
                  backgroundColor: "#128c7e",
                  transition: "width 0.1s linear"
                }}
              />
            </Box>
            <Typography variant="caption">
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
          </Box>
          <IconButton onClick={handleSendAudio} color="primary">
            <SendIcon />
          </IconButton>
        </Box>
      ) : (
        <>
          <Typography variant="body2">{formatTime(recordingTime)}</Typography>
          <IconButton
            onClick={isPaused ? resumeRecording : pauseRecording}
            color="primary"
          >
            {isPaused ? <MicIcon /> : <PauseIcon />}
          </IconButton>
          <IconButton onClick={stopRecording} color="error">
          <StopIcon />
          </IconButton>
          <IconButton onClick={resetRecorder} color="default">
            <DeleteIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default AudioRecorder;
