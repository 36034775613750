import React from "react";
import SideMenu from "../../layouts/Default/SideMenu";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BackButton from "../../components/BackButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import paymentIcon from "../../assets/images/new-icons/payments-icon.svg";

const PaymentScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleManagePaymentClick = () => {
    navigate("/payment");
  };

  return (
    <Box sx={{ display: "flex", width: "100vw", overflowX: "hidden" }}>
      <SideMenu onChangeLayoutMode={() => {}} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          minHeight: "100vh",
          position: "relative",
          padding: isMobile ? "1rem" : "2rem",
        }}
      >
        <BackButton />

        <Paper
          sx={{
            width: "100%",
            maxWidth: "40rem",
            padding: isMobile ? "1rem" : "2.5rem",
            borderRadius: "1.5rem",
            backgroundColor: "#fff",
            color: "#3A2771",
            boxShadow: "0rem 0.75rem 1.5rem rgba(0, 0, 0, 0.1)",
            zIndex: 2,
          }}
          elevation={4}
        >
          <Grid
            container
            alignItems="center"
            spacing={1}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={paymentIcon}
                  alt="Payments Icon"
                  width={isMobile ? 32 : 42}
                  height={isMobile ? 32 : 42}
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography
                variant={isMobile ? "h6" : "h5"}
                sx={{
                  fontWeight: "700",
                  color: "#4C3699",
                  fontSize: isMobile ? "1.5rem" : "2.1rem",
                  textAlign: "center",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                {t("payments")}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{ backgroundColor: "#414042", height: "0.1rem", my: "1rem" }}
          />

          <Typography
            variant="body2"
            sx={{
              color: "#414042",
              fontSize: isMobile ? "0.9rem" : "1rem",
              fontWeight: "300",
              mt: "0.5rem",
              textAlign: "start",
              overflow: "hidden",
            }}
          >
            {t("paymentDescription")}
          </Typography>

          <List sx={{ backgroundColor: "#fff", padding: 0, mt: 2 }}>
            <ListItem
              onClick={handleManagePaymentClick}
              sx={{
                cursor: "pointer",
                backgroundColor: "#F6F6F6",
                borderRadius: "10px",
                padding: "1rem",
                mb: 1,
                transition: "background-color 0.2s",
                "&:hover": { backgroundColor: "#EAEAEA" },
              }}
            >
              <ListItemText primary={t("managePayment")} />
              <ArrowForwardIosIcon
                sx={{ color: "#414042", fontSize: "1rem" }}
              />
            </ListItem>
            <ListItem
              onClick={handleManagePaymentClick}
              sx={{
                cursor: "pointer",
                backgroundColor: "#F6F6F6",
                borderRadius: "10px",
                padding: "1rem",
                transition: "background-color 0.2s",
                "&:hover": { backgroundColor: "#EAEAEA" },
              }}
            >
              <ListItemText primary={t("paymentHistory")} />
              <ArrowForwardIosIcon
                sx={{ color: "#414042", fontSize: "1rem" }}
              />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default PaymentScreen;
