import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import DefaultLayout from "../src/layouts/Default";
import NonAuthLayout from "../src/layouts/NonAuth";
import { AuthProtected } from "./routes/AuthProtected";
import { publicRoutes, privateRoutes } from "./routes/allRoutes";
import { initGA, logPageView } from "../src/utils/analytics";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import {useBeforeRender} from "./utils/useBeforeRender";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/theme.scss";
import "./assets/scss/chat.scss";

const App = () => {
  useBeforeRender(() => {
    window.addEventListener("error", e => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErrOverlay = document.getElementById(
          "webpack-dev-server-client-overlay",
        );

        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.className = "hide-resize-observer";
        }
        if (resizeObserverErrOverlay) {
          resizeObserverErrOverlay.className = "hide-resize-observer";
        }
      }
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  document.title = "Connect AI";

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
    palette: {
      primary: {
        main: "#36276F",
      },
      secondary: {
        main: "#36276F",
      },
      success: {
        main: "#7956F7",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background:
              "radial-gradient(circle at top right, #7956F7 0%, transparent 60%) no-repeat, " +
              "radial-gradient(circle at bottom left, #7956F7 0%, transparent 60%) no-repeat, " +
              "linear-gradient(145deg, #36276F, #4C3699 50%, #36276F)",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}

        {privateRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <DefaultLayout>{route.component}</DefaultLayout>
              </AuthProtected>
            }
            key={idx}
          />
        ))}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
