import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Theme,
  Tooltip,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useRedux } from "../../hooks";
import { changeTab } from "../../redux/actions";
import { TABS } from "../../constants";
import ProfileDropdownMenu from "../../components/ProfileDropdownMenu";
import FloatingSupportIcon from "../../components/FloatingSupportIcon";
import { useTranslation } from "react-i18next";

// Images / Icons
import logo from "../../assets/images/logo-icon-verde.svg";
import HomeIcon from "../../assets/images/new-icons/home-icon.svg";
import messageIcon from "../../assets/images/new-icons/message-icon.svg";
import walletIcon from "../../assets/images/new-icons/cards-icon.svg";
import settingsIcon from "../../assets/images/new-icons/settings-icon.svg";

// Caso use, mantenha; se não, pode remover.
import { getMenuItems } from "./menu";

interface SideMenuProps {
  onChangeLayoutMode: (value: any) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ onChangeLayoutMode }) => {
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation();

  const menuItems = getMenuItems(t);

  const { activeTab } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
  }));

  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.USERS);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const onChangeTab = (id: TABS) => {
    setSelectedTab(id);
    dispatch(changeTab(id));
  };

  const isActive = (path: string) => location.pathname === path;

  // icon styles
  const iconWrapperSx = {
    justifyContent: "center",
    alignItems: "center",
    padding: "0.25rem",
    borderRadius: "0.5rem",
    transition: "background-color 0.3s ease",
    "&:hover": { backgroundColor: "#4C3699" },
  };

  const listItemIconSx = {
    color: "white",
    fontSize: 24,
    display: "flex",
    justifyContent: "center",
  };

  // Component to help with tooltips
  const IconItem = ({
    to,
    iconSrc,
    label,
    tooltipPlacement = "right",
  }: {
    to: string;
    iconSrc: string;
    label: string;
    tooltipPlacement?: "right" | "top" | "bottom" | "left";
  }) => (
    <Tooltip title={t(label)} placement={tooltipPlacement} arrow>
      <ListItem component={Link} to={to} sx={iconWrapperSx}>
        <ListItemIcon sx={listItemIconSx}>
          <img src={iconSrc} alt={`${label} icon`} width="34px" />
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  );

  // Component to help with positioning the mobile icons
  const MobileIconWrapper = ({ children }: { children: React.ReactNode }) => (
    <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
      {children}
    </Box>
  );

  // --------------------------
  // Layout DESKTOP (sidebar)
  // --------------------------
  const desktopMenu = (
    <Box
      sx={{
        width: "4.6875rem",
        minHeight: "100vh",
        backgroundColor: "#36276F",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1.25rem",
        borderRight: "1px solid #000",
        boxSizing: "border-box",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <img src={logo} width="34px" alt="Logo" />
      </Box>

      <Box
        sx={{
          mt: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
        }}
      >
        {/* Support */}
        <Box sx={iconWrapperSx}>
          <FloatingSupportIcon />
        </Box>

        {/* Chat */}
        <IconItem
          to="/chat"
          iconSrc={messageIcon}
          label="Chat"
          tooltipPlacement="right"
        />

        {/* Payments */}
        {/*
        <IconItem
          to="/subscription"
          iconSrc={walletIcon}
          label="Wallet"
          tooltipPlacement="right"
        />
        */}

        {/* Settings */}
        {/*
        <IconItem
          to="/settings"
          iconSrc={settingsIcon}
          label="Settings"
          tooltipPlacement="right"
        />
        */}

        <Box sx={iconWrapperSx}>
          <ProfileDropdownMenu />
        </Box>

        {/* Ícone Home no rodapé */}
        <Box sx={{ marginTop: "2rem" }}>
          <IconItem
            to="/dashboard"
            iconSrc={HomeIcon}
            label="Home"
            tooltipPlacement="right"
          />
        </Box>
      </Box>
    </Box>
  );

  // --------------------------
  // Layout MOBILE/TABLET (bottom bar)
  // --------------------------
  const mobileMenu = (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#36276F",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "0.5rem 0",
        borderTop: "1px solid #000",
        zIndex: 999,
        boxSizing: "border-box",
      }}
    >
      {/* Chat */}
      <MobileIconWrapper>
        <IconItem
          to="/chat"
          iconSrc={messageIcon}
          label="Chat"
          tooltipPlacement="top"
        />
      </MobileIconWrapper>

      {/* Payments */}
      {/*
      <MobileIconWrapper>
        <IconItem
          to="/subscription"
          iconSrc={walletIcon}
          label="Wallet"
          tooltipPlacement="top"
        />
      </MobileIconWrapper>
      */}

      {/* Home */}
      <MobileIconWrapper>
        <IconItem
          to="/dashboard"
          iconSrc={HomeIcon}
          label="Home"
          tooltipPlacement="top"
        />
      </MobileIconWrapper>

      {/* Settings */}
      {/*
      <MobileIconWrapper>
        <IconItem
          to="/settings"
          iconSrc={settingsIcon}
          label="Settings"
          tooltipPlacement="top"
        />
      </MobileIconWrapper>
      */}

      <MobileIconWrapper>
        <Box sx={iconWrapperSx}>
          <ProfileDropdownMenu />
        </Box>
      </MobileIconWrapper>
    </Box>
  );

  // it renders the sidebar menu based on the screen size
  return <>{isMobile ? mobileMenu : desktopMenu}</>;
};

export default SideMenu;
