import React from "react";
import { Box, Typography, IconButton, Paper, Divider } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "../../components/BackButton";
import SideMenu from "../../layouts/Default/SideMenu";
import CreditCards from "../../assets/images/new-icons/credit-cards.png";

const PaymentMethodSelection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", width: "100vw", overflowX: "hidden" }}>
      <SideMenu onChangeLayoutMode={() => {}} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <BackButton />

        <Paper
          sx={{
            width: "100%",
            maxWidth: { xs: "95%", sm: "50rem" },
            padding: { xs: "1.5rem", sm: "4rem 6rem" },
            borderRadius: "1.5rem",
            backgroundColor: "#fff",
            color: "#3A2771",
            boxShadow: "0rem 0.75rem 1.5rem rgba(0, 0, 0, 0.1)",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: { xs: "auto", sm: "auto" },
          }}
          elevation={4}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              color: "#4C3699",
              fontSize: { xs: "1.5rem", sm: "2rem" },
              mb: "0.8rem",
              wordBreak: "break-word",
            }}
          >
            {t("paymentMethodTitle") || "Adicione uma forma de pagamento"}
          </Typography>

          <Divider
            sx={{
              backgroundColor: "#36276F",
              height: "0.005rem",
              my: "0.5rem",
            }}
          />

          <Typography
            variant="body1"
            sx={{
              color: "#414042",
              fontSize: { xs: "0.9rem", sm: "1.1rem" },
              fontWeight: "300",
              textAlign: "left",
              mt: "0.8rem",
              mb: "0.8rem",
            }}
          >
            {t("paymentSubtitle") ||
              "Ela só será usada como alternativa se houver um problema com a forma de pagamento atual."}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              mt: "0.5rem",
            }}
          >
            <LockIcon sx={{ fontSize: 14, color: "#4C3699", mr: 0.5 }} />
            <Typography
              variant="caption"
              sx={{
                color: "#414042",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                fontWeight: 300,
              }}
            >
              {t("securePayment") || "Criptografia de ponta a ponta"}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: "#F5F5F7",
              borderRadius: "10px",
              padding: { xs: "1rem", sm: "1.2rem" },
              mt: "1rem",
              cursor: "pointer",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              transition: "background-color 0.2s",
              "&:hover": { backgroundColor: "#EAEAEA" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, fontSize: { xs: "0.9rem", sm: "1rem" } }}
              >
                {t("creditOrDebit") || "Cartão de crédito ou débito"}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  mt: "0.5rem",
                  justifyContent: { xs: "center", sm: "flex-start" },
                }}
              >
                <img
                  src={CreditCards}
                  alt="Cartões de Crédito"
                  style={{ width: "100px" }} // Ajuste fino para mobile
                />
              </Box>
            </Box>

            {/* Ícone de Setinha */}
            <IconButton
              onClick={() => navigate("/payment-form")}
              sx={{
                color: "#4C3699",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                mt: { xs: "0.5rem", sm: "0" },
              }}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>

          {/* Link para resgate de código promocional */}
          <Typography
            variant="body2"
            sx={{
              mt: "1rem",
              color: "#414042",
              fontWeight: "600",
              fontSize: { xs: "0.9rem", sm: "1.2rem" },
              cursor: "pointer",
              textAlign: "center",
              "&:hover": { color: "#4C3699" },
            }}
            onClick={() => navigate("/redeem-code")}
          >
            {t("redeemCode") ||
              "Resgatar cartão pré-pago ou código promocional"}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default PaymentMethodSelection;
