import React, { useState } from "react";
import { Switch, Box, Typography, styled, IconButton } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "./FixedIcon.scss";

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 22,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#92EF8F",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .2)",
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#ccc",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
  },
}));

interface FixedIconProps {
  isAIEnabled: boolean;
  toggleAI: () => void;
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
}

const FixedIcon: React.FC<FixedIconProps> = ({
  isAIEnabled,
  toggleAI,
  isVisible,
  setIsVisible,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className="fixed-switch-wrapper"
      sx={{
        position: "fixed",
        bottom: 30,
        right: 20,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        gap: 1,
        backgroundColor: isVisible ? "transparent" : "#92EF8F",
        padding: isVisible ? "6px 12px" : "0",
        borderRadius: "50px",
        color: "white",
        fontWeight: "bold",
        transition: "all 0.3s ease-in-out",
      }}
    >
      {isVisible && (
        <>
          <IOSSwitch checked={isAIEnabled} onChange={toggleAI} />
          <Typography variant="body2">
            {isAIEnabled ? t("ai_enabled") : t("ai_disabled")}
          </Typography>
        </>
      )}
      <IconButton
        onClick={() => setIsVisible(!isVisible)}
        sx={{ color: "white" }}
      >
        {isVisible ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
    </Box>
  );
};

export default FixedIcon;
