// src/pages/Dashboard/Profile/StatusPageMock.tsx
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LiaQrcodeSolid } from "react-icons/lia";
import { toast, ToastContainer } from "react-toastify";

// Material UI Components
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";

// Mocked logic imports
import { AccountNameForm } from "./NewProfile";
import { WebhookQrModal } from "./GetQRCode";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  StateType,
} from "../../../utils/webSocketUtils";

// Assets / icons
import connectaiIconPurple from "../../../assets/images/new-icons/connectai-icon-purple.svg";
import whatsappLogo from "../../../assets/images/new-icons/wpp-green-icon-square.png";

// Types
import { Session } from "../types";

// --- Mocked session data ---
const initialMockSessions: Session[] = [
  {
    id: "1",
    name: "Sessão Conectada",
    phone_number: "+55 71 9906-4981",
    status: "WORKING",
    companyId: "comp1",
    origin: "whatsapp",
    userLevel: "admin",
    is_historic_update: false,
    belongs_to_ai: false,
  },
  {
    id: "2",
    name: "Sessão QR Code",
    phone_number: "+55 71 9999-8888",
    status: "SCAN_QR_CODE",
    companyId: "comp2",
    origin: "whatsapp",
    userLevel: "admin",
    is_historic_update: false,
    belongs_to_ai: false,
  },
  {
    id: "3",
    name: "Sessão Desconectada",
    phone_number: "+55 71 8888-7777",
    status: "STOPPED",
    companyId: "comp3",
    origin: "whatsapp",
    userLevel: "admin",
    is_historic_update: false,
    belongs_to_ai: false,
  },
  {
    id: "4",
    name: "Sessão Iniciando",
    phone_number: "+55 71 7777-6666",
    status: "STARTING",
    companyId: "comp4",
    origin: "whatsapp",
    userLevel: "admin",
    is_historic_update: false,
    belongs_to_ai: false,
  },
];

// Helper: format phone number
const formatPhoneNumber = (phoneNumber: string): string => {
  let countryCode, areaCode, mainNumber, formattedNumber;
  if (phoneNumber.startsWith("971")) {
    countryCode = "971";
    areaCode = phoneNumber.slice(3, 5);
    mainNumber = phoneNumber.slice(5);
    formattedNumber =
      mainNumber.length === 7
        ? `${mainNumber.slice(0, 3)}-${mainNumber.slice(3)}`
        : `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`;
    return `+${countryCode} ${areaCode} ${formattedNumber}`;
  } else if (phoneNumber.startsWith("55")) {
    countryCode = "55";
    const numberWithoutCountryCode = phoneNumber.slice(2);
    areaCode = numberWithoutCountryCode.slice(0, 2);
    mainNumber = numberWithoutCountryCode.slice(2);
    formattedNumber =
      mainNumber.length === 8
        ? `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`
        : `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;
    return `+${countryCode} ${areaCode} ${formattedNumber}`;
  } else if (phoneNumber.startsWith("1")) {
    countryCode = "1";
    const numberWithoutCountryCode = phoneNumber.slice(1);
    areaCode = numberWithoutCountryCode.slice(0, 3);
    mainNumber = numberWithoutCountryCode.slice(3);
    formattedNumber = `${mainNumber.slice(0, 3)}-${mainNumber.slice(3)}`;
    return `+${countryCode} (${areaCode}) ${formattedNumber}`;
  } else {
    return phoneNumber;
  }
};

// Returns status label
const getStatusLabel = (status: string): string => {
  switch (status) {
    case "WORKING":
      return "Conectado";
    case "STOPPED":
      return "Desconectado";
    case "SCAN_QR_CODE":
      return "Scan QR Code";
    case "STARTING":
      return "Starting";
    default:
      return "Desconhecido";
  }
};

// Returns status color
const getStatusColor = (status: string): string => {
  switch (status) {
    case "WORKING":
      return "#4CAF50";
    case "STOPPED":
      return "#414042";
    case "SCAN_QR_CODE":
      return "#FFA500";
    case "STARTING":
      return "#4C3699";
    default:
      return "#9E9E9E";
  }
};

const StatusPageMock: React.FC = () => {
  const { t } = useTranslation();

  // Hard-coded role as admin for demonstration
  const role = "admin";
  const buttonRef = useRef<HTMLButtonElement>(null);

  // States
  const [sessions, setSessions] = useState<Session[]>(initialMockSessions);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState<Session | null>(null);
  const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement>(
    null,
  );
  const [dropdownSessionName, setDropdownSessionName] = useState<string | null>(
    null,
  );

  // Mock WebSocket state
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [state, setState] = useState<StateType>({
    qrCode: "MOCK_QR_CODE",
    showQrCode: false,
    webSocketLoading: false,
    loading: false,
    status: null,
    error: null,
  });

  // Mock "active tour" function
  const activeTour = () => {
    console.log("Active tour triggered");
  };

  // Filter sessions
  const filteredSessions = sessions.filter(s =>
    s.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Truncate text
  const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  // Handle search input
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Simulate session deletion
  const handleDeleteSession = async () => {
    if (sessionToDelete) {
      setLoadingSearch(true);
      setSessions(prev => prev.filter(s => s.id !== sessionToDelete.id));
      setSessionToDelete(null);
      setConfirmModalOpen(false);
      setLoadingSearch(false);
    }
  };

  const confirmDeleteSession = (session: Session) => {
    setSessionToDelete(session);
    setConfirmModalOpen(true);
  };

  // Toggle account creation modal (mock creation)
  const toggleAccountModal = () => {
    if (sessions.length >= 4) {
      toast.error("Session limit");
    } else {
      setAccountModalOpen(!accountModalOpen);
    }
  };

  // Toggle QR code modal (no real WebSocket)
  const toggleQrCodeModal = () => {
    setQrCodeModalOpen(!qrCodeModalOpen);
  };

  // Pill click (SCAN_QR_CODE -> open QR code modal)
  const handlePillClick = (status: string) => {
    if (status === "SCAN_QR_CODE") {
      toggleQrCodeModal();
    }
  };

  // Dropdown
  const handleDropdownClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    sessionName: string,
  ) => {
    setDropdownAnchor(event.currentTarget);
    setDropdownSessionName(sessionName);
  };

  const handleDropdownClose = () => {
    setDropdownAnchor(null);
    setDropdownSessionName(null);
  };

  // Toggle confirm deletion modal
  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen);
  };

  return (
    <Container className="status-page-container" sx={{ py: 4 }}>
      <ToastContainer />

      {/* Loading Spinner */}
      <Box
        className="div-area"
        sx={{
          display: loadingSearch ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <CircularProgress />
      </Box>

      {/* With Sessions */}
      {sessions.length > 0 && !loadingSearch && (
        <Box className="sessions-area" sx={{ mb: sessions.length > 0 ? 2 : 0 }}>
          {/* Search Bar & "New Session" Button */}
          <Box
            className="d-flex align-items-center mb-2"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              flexWrap: "wrap", // allow wrapping on small screens
              gap: 2,
            }}
          >
            <Box
              className="search-bar"
              sx={{
                width: { xs: "100%", md: "38.13rem" }, // Responsive
                height: "4.38rem",
                backgroundColor: "#F6F6F6",
                borderRadius: "0.53rem",
                px: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Pesquisar Sessões..."
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: 14 },
                }}
              />
              <IconButton>
                <SearchIcon sx={{ color: "#414042" }} />
              </IconButton>
            </Box>

            {role === "admin" && (
              <Box
                className="d-flex align-items-center"
                sx={{ position: "relative" }}
              >
                <Button
                  ref={buttonRef}
                  sx={{
                    width: "3.81rem", // 61px
                    height: "3.81rem",
                    borderRadius: "0.53rem", // 8.46px
                  }}
                  onClick={toggleAccountModal}
                  className=" d-flex align-items-center"
                >
                  <Box
                    component="img"
                    src={connectaiIconPurple}
                    alt="Connect AI"
                    sx={{ width: "100%", height: "100%" }}
                  />
                </Button>
                <Box
                  sx={{
                    position: "absolute",
                    top: "-0.31rem",
                    right: "-0.31rem",
                    width: "1.25rem",
                    height: "1.25rem",
                    backgroundColor: "#414042",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: "#fff", fontSize: 12 }}
                  >
                    +
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {filteredSessions.length === 0 && searchTerm && (
            <Box sx={{ color: "red", fontSize: 14, mt: 0.5 }}>
              Nenhuma sessão encontrada.
            </Box>
          )}

          <Grid container spacing={2}>
            {filteredSessions.map(session => (
              <Grid item xs={12} md={6} key={session.id}>
                <Box
                  sx={{
                    backgroundColor: "#F6F6F6",
                    borderRadius: "0.53rem",
                    maxWidth: "22.6rem",
                    minHeight: "7.56rem",
                    p: 2,
                    width: { xs: "100%", sm: "auto" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxShadow: 1,
                  }}
                >
                  {/* Left: WhatsApp icon + name + phone */}
                  <Box
                    className="d-flex align-items-center"
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <Box
                      component="img"
                      src={whatsappLogo}
                      alt="WhatsApp icon"
                      sx={{ width: 40, height: 40 }}
                    />
                    <Box sx={{ alignSelf: "flex-start" }}>
                      <Tooltip title={session.name} arrow>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            mb: 0.5,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "12rem",
                          }}
                        >
                          {truncateText(session.name, 15)}
                        </Typography>
                      </Tooltip>
                      {session.phone_number && (
                        <Typography variant="body2" sx={{ color: "#414042" }}>
                          {formatPhoneNumber(session.phone_number)}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* Right: Status pill & menu */}
                  <Box
                    className="d-flex align-items-end"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      height: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      onClick={() => {
                        if (session.status === "SCAN_QR_CODE")
                          toggleQrCodeModal();
                      }}
                      sx={{
                        backgroundColor: getStatusColor(session.status),
                        color: "#fff",
                        borderRadius: "1rem",
                        px: 2,
                        py: 0.5,
                        mb: "auto",
                        cursor:
                          session.status === "SCAN_QR_CODE"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                        {getStatusLabel(session.status)}
                      </Typography>
                    </Box>
                    {role === "admin" && (
                      <IconButton
                        onClick={e => {
                          setDropdownAnchor(e.currentTarget);
                          setDropdownSessionName(session.name);
                        }}
                      >
                        <MoreVertIcon sx={{ color: "#414042" }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Without Sessions */}
      {sessions.length === 0 && !loadingSearch && (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box>
              <Typography sx={{ mt: 5, mb: 4 }} variant="h5">
                Conecte a Jess no seu WhatsApp
              </Typography>
              <ol style={{ paddingLeft: "1.25rem", margin: 0 }}>
                <li style={{ marginBottom: "0.5rem" }}>
                  Abra o WhatsApp no seu telefone
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  Toque no Menu no Android, ou Configurações no iPhone
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  Toque em Dispositivos Conectados e depois em Conectar um
                  dispositivo
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  Aperte o botão, adicione um nome a essa sessão e aponte a
                  câmera do seu telefone para o QR CODE que irá aparecer.
                </li>
              </ol>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: { xs: 2, lg: 0 },
              }}
            >
              <Button
                ref={buttonRef}
                variant="contained"
                color="primary"
                onClick={toggleAccountModal}
                sx={{ mb: 2 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LiaQrcodeSolid style={{ fontSize: "2rem" }} />
                  <Typography sx={{ mt: 1 }}>
                    Toque aqui para ver o QR CODE
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ borderBottom: "0.0625rem solid #ccc", my: "0.125rem" }} />
          <Typography sx={{ mt: 3, textAlign: "center" }}>
            support_whatsapp_api
          </Typography>
          <Box>
            <a
              onClick={activeTour}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Precisa de ajuda?
            </a>
          </Box>
        </Box>
      )}

      {/* Dropdown Menu for deletion */}
      <Menu
        anchorEl={dropdownAnchor}
        open={Boolean(dropdownAnchor) && dropdownSessionName !== null}
        onClose={() => {
          setDropdownAnchor(null);
          setDropdownSessionName(null);
        }}
      >
        <MenuItem
          onClick={() => {
            const s = filteredSessions.find(
              sess => sess.name === dropdownSessionName,
            );
            if (s) {
              setSessionToDelete(s);
              setConfirmModalOpen(true);
            }
            setDropdownAnchor(null);
            setDropdownSessionName(null);
          }}
        >
          Deletar sessão
        </MenuItem>
      </Menu>

      {/* Modals */}
      {role === "admin" && (
        <>
          <Dialog open={accountModalOpen} onClose={toggleAccountModal}>
            <DialogTitle sx={{ fontSize: "25px" }}>Criar Sessão</DialogTitle>
            <DialogContent>
              <AccountNameForm
                isOpen={accountModalOpen}
                toggle={toggleAccountModal}
                // Casting to avoid TS2322: Type '(newSessionName: string) => void' is not assignable to type '() => void'
                updateSessions={
                  ((newSessionName: string) => {
                    // Mock session creation
                    const newSession: Session = {
                      id: (Math.random() * 100000).toFixed(0),
                      name: newSessionName,
                      phone_number: "+55 71 9999-9999",
                      status: "STOPPED",
                      companyId: "mockCompany",
                      origin: "whatsapp",
                      userLevel: "admin",
                      is_historic_update: false,
                      belongs_to_ai: false,
                    };
                    setSessions(prev => [...prev, newSession]);
                    toggleAccountModal();
                  }) as unknown as () => void
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleAccountModal}>Fechar</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={qrCodeModalOpen}
            onClose={toggleQrCodeModal}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>QR Code</DialogTitle>
            <DialogContent>
              <WebhookQrModal
                isOpen={qrCodeModalOpen}
                toggle={toggleQrCodeModal}
                existingSocket={socket}
                qrCode={state.qrCode}
                showQrCode={state.showQrCode}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleQrCodeModal}>Fechar</Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <Dialog open={confirmModalOpen} onClose={toggleConfirmModal}>
        <DialogTitle sx={{ fontSize: "25px" }}>
          Confirmação de Deleção
        </DialogTitle>
        <DialogContent>
          Tem certeza que deseja deletar a sessão {sessionToDelete?.name}?
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={handleDeleteSession}
            disabled={loadingSearch}
          >
            {loadingSearch ? "Deletando..." : "Deletar"}
          </Button>
          <Button color="secondary" onClick={toggleConfirmModal}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StatusPageMock;
