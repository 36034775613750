import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import whatsappLogo from "../../../assets/images/whatsapp.svg";
import "../../../assets/scss/custom.scss";
import { QrCodeDisplay } from "./QrCodeDisplay";
import Cookies from "js-cookie";
import { URLS } from "../../../constants";
import { useTranslation } from "react-i18next";
import {
  connectWebSocket,
  handleWebSocketMessage,
  handleWebSocketError,
  handleWebSocketClose,
  StateType,
} from "../../../utils/webSocketUtils";

interface AccountNameFormProps {
  isOpen: boolean;
  toggle: () => void;
  updateSessions?: () => void;
  channel?: string;
}

const initialState = {
  name: "",
  whatsappBusinessID: "",
  whatsappPhoneID: "",
  loading: false,
  error: null as string | null,
  successMessage: null as string | null,
  attemptedSubmit: false,
  buttonBlocked: false,
  qrCode: null,
  showQrCode: false,
  webSocketLoading: true,
  status: null,
  nameError: null,
};

export const AccountNameForm: React.FC<AccountNameFormProps> = ({
                                                                  isOpen,
                                                                  toggle,
                                                                  updateSessions,
                                                                  channel,
                                                                }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(initialState);
  const socket = useRef<WebSocket | null>(null);

  const URL = `${URLS.CLIENT_URL}/api/starting`;
  const WEBSOCKET_URL = `${URLS.WEBSOCKET_URL}/api/ws`;

  useEffect(() => {
    return () => {
      handleWebSocketClose(socket.current);
    };
  }, []);

  const apiKeyCookie = Cookies.get("api-key");
  const config = {
    headers: {
      "Content-Type": "application/json",
      APIKEY: apiKeyCookie,
    },
  };

  // 🔵 Lógica para API_OFFICIAL (Facebook)
  const handleSubmitMeta = async (e: React.FormEvent) => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, attemptedSubmit: true }));

    if (state.loading || state.buttonBlocked || state.name.trim().length < 3) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
      buttonBlocked: true,
      error: null,
    }));

    const payload = {
      name: state.name,
      channel: channel,
      whatsapp_business: state.whatsappBusinessID,
      whatsapp_phone_id: state.whatsappPhoneID,
    };

    try {
      await axios.post(URL, payload, config);
      setState(prevState => ({
        ...prevState,
        successMessage: t("Facebook session created successfully!"),
        loading: false,
        buttonBlocked: false,
      }));
      if (updateSessions) {
        updateSessions();
      }
      handleToggle();
    } catch (error) {
      console.error("Error during Facebook submission:", error);
      setState(prevState => ({
        ...prevState,
        error: t("An error occurred while creating the Facebook session."),
        loading: false,
        buttonBlocked: false,
      }));
    }
  };

  // 🟢 Lógica para Waha (WhatsApp)
  const handleSubmitDefault = async (e: React.FormEvent) => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, attemptedSubmit: true }));

    if (state.loading || state.buttonBlocked || state.name.trim().length < 3) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      loading: true,
      buttonBlocked: true,
      error: null,
    }));

    const payload = {
      name: state.name,
      channel: channel,
      whatsapp_business: "",
      whatsapp_phone_id: ""
    };

    try {
      await axios.post(URL, payload, config);
      setState(prevState => ({
        ...prevState,
        successMessage: t("Session created successfully!"),
        buttonBlocked: false,
      }));

      socket.current = connectWebSocket(
        WEBSOCKET_URL,
        event => handleWebSocketMessage(event, setState, t("QR code successfully scanned!")),
        event => handleWebSocketError(event, setState, t("WebSocket error observed")),
        event => {
          console.log("WebSocket connection closed");
          if (updateSessions) {
            updateSessions();
          }
          handleToggle();
        }
      );
    } catch (error) {
      console.error("Error during submission:", error);
      setState(prevState => ({
        ...prevState,
        error: t("An error occurred while creating the session."),
        loading: false,
        buttonBlocked: false,
      }));
    }
  };

  const handleToggle = () => {
    toggle();
    setState(initialState);
  };

  return (
    <Dialog open={isOpen} onClose={toggle} maxWidth="xs" fullWidth PaperProps={{ style: { borderRadius: 12 } }}>
      <DialogTitle>
        <Typography variant="h6" component="span" style={{ color: "#FFFFFF" }}>
          {t("Criar sessão")}
        </Typography>
        <IconButton aria-label="close" onClick={handleToggle} sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container className="d-flex flex-column align-items-center justify-content-center">
          <div id="logo" style={{ paddingBottom: "20px" }}>
            <img src={whatsappLogo} alt={t("Logo")} width={"80"} />
          </div>
          <Typography variant="h6" component="h1" align="center"
                      style={{ fontSize: "18px", fontWeight: 500, color: "#333" }}>
            {t("Enter your new account here")}
          </Typography>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              width: "20em",
              margin: "0",
              color: "#414042",
            }}
          >
            {t("This name will appear so you know which account is connected.")}
          </p>

          {state.loading ? (
            <CircularProgress size={80} color="secondary" />
          ) : state.showQrCode && state.qrCode ? (
            <QrCodeDisplay qrCode={state.qrCode} />
          ) : state.status === "WORKING" ? (
            <Typography>{t("Connected Card")}</Typography>
          ) : (
            <>
              {state.successMessage && (
                <Alert severity="success" className="mt-3">
                  {state.successMessage}
                </Alert>
              )}
                <form onSubmit={channel === "API_OFFICIAL" ? handleSubmitMeta : handleSubmitDefault} className="w-100">
                  <TextField label={t("Account Name")} type="text" fullWidth margin="normal"
                             placeholder={t("Account Name")}
                             value={state.name}
                             onChange={e => setState(prevState => ({ ...prevState, name: e.target.value }))} />

                  {channel === "API_OFFICIAL" && (
                    <>
                      <TextField
                        label={t("Whatsapp Business ID")}
                        type="text"
                        fullWidth
                        margin="normal"
                        placeholder={t("Whatsapp Business ID")}
                        value={state.whatsappBusinessID}
                        onChange={e => setState(prevState => ({ ...prevState, whatsappBusinessID: e.target.value }))}
                      />

                      <TextField
                        label={t("Whatsapp Phone ID")}
                        type="text"
                        fullWidth
                        margin="normal"
                        placeholder={t("Whatsapp Phone ID")}
                        value={state.whatsappPhoneID}
                        onChange={e => setState(prevState => ({ ...prevState, whatsappPhoneID: e.target.value }))}
                      />
                    </>
                  )}

                  <DialogActions className="d-flex justify-content-center">
                    <Button type="submit" variant="contained" fullWidth>
                      {channel === "Waha" ? t("Scan QR Code") : t("Save")}
                    </Button>
                  </DialogActions>
                </form>
            </>
          )}
        </Container>
      </DialogContent>
      <ToastContainer />
    </Dialog>
  );
};
