import React, { useState, useEffect, useCallback, useRef } from 'react';
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  Card,
  CardBody,
  Container
} from 'reactstrap';

import Joyride, { Step, CallBackProps } from 'react-joyride';

// hooks
import { useRedux } from "../../hooks/index";

// components
import StatusPage from "./Profile/StatusPage";
import JessPage from "./Profile/JessPage";
import FixedIcon from "./Ai/FixedIcons";
import {STATUS, URLS} from "../../constants";
import axios from "axios";
import { Session } from "./types";
import WaveDashboard from "../../components/waveLine/WaveDashboard";

const Index: React.FC = () => {
  const { useAppSelector } = useRedux();
  const { t } = useTranslation();
  const errorData = (state: any) => ({
    selectedChat: state.Chats.selectedChat,
    userProfile: state.Profile.userProfile,
  });
  const { selectedChat, userProfile } = useAppSelector(errorData);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(false);
  const [isAgentEnabled, setIsAgentEnabled] = useState<boolean>(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const apiKey = Cookies.get("api-key");
  const axiosInstance = axios.create({
    baseURL: URLS.CLIENT_URL,
    headers: {
      "Content-Type": "application/json",
      APIKEY: apiKey,
    },
  });

  const connectANewWhatsappAccountButtonRef = useRef<HTMLButtonElement>(null);
  const [isSwitchVisible, setIsSwitchVisible] = useState(() => {
    const savedVisibility = localStorage.getItem("switchVisibility");
    return savedVisibility ? JSON.parse(savedVisibility) : true;
  });
  const toggleVisibility = () => {
    setIsSwitchVisible((prev: boolean) => {
      const newState = !prev;
      localStorage.setItem("switchVisibility", JSON.stringify(newState));
      return newState;
    });
  };

  const [runTour, setRunTour] = useState(false);

  const [steps, setSteps] = useState<Step[]>([
    // Example:
    // {
    //   target: '.tab-jess',
    //   content: t("tourJoyride.jessArea"),
    //   disableBeacon: true
    // },
  ]);

  useEffect(() => {
    const newSteps = [...steps];

    if (
      !steps.some(
        step => step.target === connectANewWhatsappAccountButtonRef.current,
      )
    ) {
      newSteps.unshift({
        target: connectANewWhatsappAccountButtonRef.current as HTMLElement,
        content: t("tourJoyride.whatsappButtonAdd"),
        disableBeacon: true,
      });
    }

    if (newSteps.length > steps.length) {
      setSteps(newSteps);
    }
  }, [connectANewWhatsappAccountButtonRef, steps, t]);

  useEffect(() => {
    const hasSeenTour = Cookies.get("has_seen_tour");

    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get("/api/get-status");
        const result = response.data;

        const sessions = Array.isArray(result.session_status)
          ? result.session_status.map((session: any) => ({
              ...session,
              phoneNumber: session.phone_number || "",
            }))
          : [];

        setSessions(sessions);
        setIsAIEnabled(result.isAIEnabled);
        setIsAgentEnabled(result.websocketConnected);
      } catch (error) {
        console.error("Failed to fetch status:", error);
      } finally {
        setLoading(false);

        if (!hasSeenTour && sessions.length <= 1) {
          setRunTour(true);
        }
      }
    };

    fetchStatus();
  }, []);

  const activeTour = () => {
    setRunTour(true);
  };

  const userRole = userProfile?.role || "admin";

  const updateSessions = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/api/get-status");
      const result = response.data;

      if (
        result.session_status &&
        Array.isArray(result.session_status) &&
        result.session_status.length > 0
      ) {
        setSessions(result.session_status);
      } else {
        setSessions([]);
        console.error("session_status is not an array or empty:", result);
      }
    } catch (error) {
      console.error("Failed to update sessions:", error);
    }
  }, [axiosInstance]);

  const handleToggleAI = async () => {
    const newStatus = !isAIEnabled;
    try {
      await axiosInstance.post("/api/toggle-ai", { isAIEnabled: newStatus });
      setIsAIEnabled(newStatus);
    } catch (error) {
      console.error("Failed to update toggle status:", error);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      setRunTour(false);
      Cookies.set("has_seen_tour", "true", { expires: 365 });
    }
  };

  const jessSession = sessions.find(session => session.belongs_to_ai);
  const customersSessions = sessions.filter(session => !session.belongs_to_ai);

  const determineJessStatus = (
    isAgentEnabled: boolean,
    jessSession: Session,
    otherSessions: Session[],
  ): STATUS => {
    const hasWorkingSession = otherSessions.some(
      session => session.status === "WORKING",
    );
    const hasOtherSessions = otherSessions.length > 0;

    if (jessSession.status === "STOPPED") {
      return STATUS.Offline;
    }

    if (!isAgentEnabled) {
      return STATUS.OpenAgent;
    }

    if (isAgentEnabled) {
      if (hasOtherSessions && hasWorkingSession) {
        return STATUS.Online;
      }
      return STATUS.ConectWhatsapp;
    }

    // Fallback case: should not occur if all conditions above are handled
    return STATUS.ConectWhatsapp;
  };

  const jessStatus = jessSession
    ? determineJessStatus(isAgentEnabled, jessSession, customersSessions)
    : STATUS.Offline; // Default if jessSession is unexpectedly null.
  const jessPhoneNumber = jessSession?.phone_number || "";

  const loadingControl = (value: boolean) => {
    setLoading(value);
  };

  return (
    <>
      <Joyride
        steps={steps}
        run={runTour}
        continuous
        scrollToFirstStep
        showProgress
        disableOverlayClose={true}
        disableOverlay={false}
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: t("tourJoyride.Back"),
          close: t("tourJoyride.Close"),
          last: t("tourJoyride.Last"),
          next: t("tourJoyride.Next"),
          skip: t("tourJoyride.Skip"),
        }}
        styles={{
          options: {
            primaryColor: "#4C3699",
            zIndex: 10000,
          },
        }}
      />
      <div
        className={classnames("user-chat", "w-100", "overflow-x-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>

        <div className="max-card-area">
          <Container
            style={{ maxWidth: "50em", marginTop: "5em" }}
            className="card-status d-flex flex-column"
          >
            <Card className="big-card container-card">
              <CardBody>
                <JessPage
                  jessPhoneNumber={jessPhoneNumber}
                  statusJess={jessStatus}
                />
                <StatusPage
                  ref={connectANewWhatsappAccountButtonRef}
                  sessions={customersSessions}
                  role={userRole}
                  updateSessions={updateSessions}
                  loadingSearch={loading}
                  loadingControl={loadingControl}
                  activeTour={activeTour}
                  isAgentEnabled={isAgentEnabled}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
        <FixedIcon
          isAIEnabled={isAIEnabled}
          toggleAI={handleToggleAI}
          isVisible={isSwitchVisible}
          setIsVisible={toggleVisibility}
        />
      </div>
    </>
  );
};

export default Index;
