import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { PictureAsPdf, Description, Download } from "@mui/icons-material";
import { formatDate } from "../../../utils";
import { MessagesTypes } from "../../../data/messages";

interface MessageProps {
  message: MessagesTypes;
  chatUserDetails: any;
  isFromMe: boolean;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
}

const Message = ({ message, chatUserDetails, isFromMe }: MessageProps) => {
  // console.log("Message:", message); TODO: REMOVE THIS
  // console.log("ChatUserDetails:", chatUserDetails); TODO: REMOVE THIS
  // console.log("IsFromMe:", isFromMe); TODO: REMOVE THIS
  console.log("Raw message.time:", message.time);
  console.log("Formatted Date:", formatDate(message.time, "hh:mmaaa"));
  const { t } = useTranslation();
  const date = formatDate(message.time, "hh:mmaaa");
  const [preview, setPreview] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const openPreview = (link: string) => {
    setPreview(link);
  };

  const closePreview = () => {
    setPreview(null);
  };

  const renderAttachments = () => {
    if (!message.attachments || message.attachments.length === 0) return null;

    return message.attachments.map((attachment, index) => {
      if (!attachment.name || !attachment.downloadLink) {
        console.warn(t("invalid_attachment"), attachment);
        return null;
      }

      // const fileType = attachment.name.split(".").pop()?.toLowerCase();
      const fileType = attachment.mimetype?.split("/")[0]
      const fileExtension = attachment.name.split(".").pop()?.toLowerCase() || "";

      console.log("fileType:", fileType);
      console.log("attachment:", attachment);

      const documentTypes = ["pdf", "msword", "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "vnd.ms-excel", "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "vnd.ms-powerpoint", "vnd.openxmlformats-officedocument.presentationml.presentation"];

      if (documentTypes.includes(attachment.mimetype) || ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension)) {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              padding: "10px",
              backgroundColor: "#2A2A2A",
              borderRadius: "8px",
              maxWidth: "400px",
              color: "#FFF",
            }}
          >
            {/* Ícone do arquivo */}
            <Avatar
              sx={{
                bgcolor: "transparent",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {fileExtension === "pdf" ? <PictureAsPdf color="error" fontSize="large" /> : <Description color="primary" fontSize="large" />}
            </Avatar>

            {/* Informações do arquivo */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1" fontWeight="bold">
                {attachment.name}
              </Typography>
            </Box>

            {/* Botão de Download */}
            <IconButton component="a" href={attachment.downloadLink} target="_blank" rel="noopener noreferrer">
              <Download />
            </IconButton>
          </Box>
        );
      }

      if (["audio"].includes(fileType)) {
        const audioUrl = attachment.base64Data
          ? `data:${attachment.mimetype};base64,${attachment.base64Data}`
          : attachment.downloadLink; // Usa o link caso base64Data não esteja disponível

        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              padding: "8px 16px",
              width: "100%",
              maxWidth: "400px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              backgroundColor: "transparent",
            }}
          >
            <audio controls>
              <source src={audioUrl} type={attachment.mimetype} />
              Seu navegador não suporta o elemento de áudio.
            </audio>
          </Box>
        );
      }


      // Render images
      if (fileType === "image") {
        const imageUrl = attachment.base64Data
          ? `data:${attachment.mimetype};base64,${attachment.base64Data}`
          : attachment.downloadLink; // Usa downloadLink caso base64Data não esteja disponível

        return (
          <Box key={index} mt={1}>
            <img
              src={imageUrl}
              alt="Attachment"
              style={{
                maxWidth: "70%",
                borderRadius: "5px",
                marginTop: "1px",
                cursor: "pointer",
              }}
              onClick={() => openPreview(imageUrl)}
            />
            {attachment.caption && (
              <Typography variant="body2" mt={1} color="textSecondary">
                {attachment.caption}
              </Typography>
            )}
          </Box>
        );
      }


      return null;
    });
  };

  return (
    <Box
      display="flex"
      justifyContent={isFromMe ? "flex-end" : "flex-start"}
      alignItems="flex-end"
      sx={{ mb: 2 }}
    >
      {!isFromMe && (
        <Avatar
          src={chatUserDetails?.profileImage || undefined}
          alt={chatUserDetails?.name || "User"}
          sx={{ mr: 1, width: 40, height: 40 }}
        />
      )}

      <Box
        sx={{
          maxWidth: "60%",
          minWidth: "120px",
          padding: "12px 16px 30px",
          borderRadius: isFromMe ? "16px 16px 0 16px" : "16px 16px 16px 0",
          backgroundColor: isFromMe ? "primary.main" : "grey.200",
          color: isFromMe ? "primary.contrastText" : "text.primary",
          position: "relative",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {message.text && !message.attachments?.some(att => att.mimetype?.startsWith("audio")) && (
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              lineHeight: "1.8",
            }}
          >
            {message.text}
          </Typography>
        )}

        {renderAttachments()}

        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            bottom: "8px",
            right: "12px",
            fontSize: "12px",
            color: isFromMe ? "primary.contrastText" : "grey.600",
          }}
        >
          {date}
        </Typography>
      </Box>

      {isFromMe && (
        <Avatar
          src={chatUserDetails?.whatsappProfilePic || undefined}
          alt="You"
          sx={{ ml: 1, width: 40, height: 40 }}
        />
      )}

      <Dialog open={Boolean(preview)} onClose={closePreview} fullWidth>
        <DialogContent>
          {preview && (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Message;
