import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Popper } from "@mui/material";
import Picker, { EmojiClickData } from "emoji-picker-react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

interface EmojiPickerProps {
  onSelectEmoji: (emoji: string) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onSelectEmoji }) => {
  const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        anchorEl &&
        !anchorEl.contains(event.target as Node)
      ) {
        setEmojiPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  return (
    <Box>
      {/* Button to open Emoji Picker */}
      <IconButton
        onClick={event => {
          setAnchorEl(event.currentTarget);
          setEmojiPickerOpen(prev => !prev);
        }}
      >
        <EmojiEmotionsIcon />
      </IconButton>

      {/* Emoji Picker Popper */}
      <Popper
        open={emojiPickerOpen}
        anchorEl={anchorEl}
        placement="top-start"
        style={{ zIndex: 1300 }}
      >
        <Box
          ref={popperRef}
          sx={{ background: "white", borderRadius: "8px", boxShadow: 3 }}
        >
          <Picker
            onEmojiClick={(emojiData: EmojiClickData) => {
              onSelectEmoji(emojiData.emoji);
              setEmojiPickerOpen(false);
            }}
          />
        </Box>
      </Popper>
    </Box>
  );
};

export default EmojiPicker;
