import React, { useState } from "react";
import { Box } from "@mui/material";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes, ConversationTypes, UserTypes } from "../../../data";
import Cookies from "js-cookie";
import { URLS } from "../../../constants";
import convertAudioToOpus from "../Settings/convertAudioToOpus";

interface IndexProps {
  isChannel: boolean;
  contact?: UserTypes;
  conversation: ConversationTypes;
}

const Index = ({ isChannel, contact, conversation }: IndexProps) => {
  console.log("ConversationUser: ", conversation);

  const { userProfile } = useProfile();
  const [replyData, setReplyData] = useState<null | MessagesTypes | undefined>();

  const prepareBackendPayload = (message: MessagesTypes, contactId: string | number) => {
    console.log("BEFORE prepareBackendPayload: ", message);

    const test = {
      clientID: String(contactId),
      messageID: String(contactId),
      answeringTo: !!message.replyOf,
      chatId: String(contactId),
      hasMedia: (message.image?.length ?? 0) > 0 || (message.attachments?.length ?? 0) > 0,
      originsIA: true,
      shouldConvertToSpeech: false,
      shouldReply: !!message.replyOf,
      session: message.session,
      channel: message.channel,
      data: {
        message: message.text || "",
        timestamp: new Date().toISOString(),
      },
      media: (message.attachments || []).map((media) => ({
        base64Data: media.base64Data,
        filename: media.name,
        mimetype: media.mimetype,
        url: media.downloadLink,
      })),
    };

    console.log("AFTER prepareBackendPayload: ", test);
    return test
  };

  const sendToBackend = async (payload: any) => {
    try {
      const apiKey = Cookies.get("api-key");
      if (!apiKey) {
        console.error("API Key não encontrada");
        return;
      }

      const response = await fetch(`${URLS.CLIENT_URL}/api/receive-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APIKEY: apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Falha ao enviar mensagem: ${response.statusText}`);
      }

      console.log("Mensagem enviada ao backend com sucesso:", await response.json());
    } catch (error) {
      console.error("Erro ao enviar mensagem ao backend:", error);
    }
  };

  const onSend = async (data: MessagesTypes) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    const newMessage: MessagesTypes = {
      mId: new Date().getTime(),
      text: data.text || "",
      time: new Date().toISOString(),
      image: data.image || [],
      attachments: data.attachments || [],
      session: conversation.messages[0].session,
      channel: conversation.messages[0].channel,
      meta: {
        receiver: contact.id as string | number,
        sender: "You",
        sent: true,
        received: false,
        read: true,
      },
      replyOf: replyData || undefined,
    };

    const backendPayload = prepareBackendPayload(newMessage, contact.id);
    await sendToBackend(backendPayload);
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); // Lê o Blob como Base64
      reader.onloadend = () => {
        const base64String = (reader.result as string).split(",")[1]; // Remove o prefixo "data:audio/mpeg;base64,"
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const onSendAudio = async (audioBlob: Blob) => {
    if (!contact?.id) {
      console.error("ID do contato não definido");
      return;
    }

    try {
      const convertedBlob = await convertAudioToOpus(audioBlob);
      const base64Audio = await blobToBase64(convertedBlob);

      const audioMessage: MessagesTypes = {
        mId: conversation.messages[0].mId,
        text: undefined,
        time: new Date().toISOString(),
        session: conversation.messages[0].session,
        channel: conversation.messages[0].channel,
        meta: {
          receiver: contact.id as string | number,
          sender: "You",
          sent: true,
          received: false,
          read: true,
        },
        attachments: [
          {
            id: new Date().getTime(),
            downloadLink: "",
            base64Data: base64Audio,
            name: `${conversation.messages[0].mId}.opus`,
            desc: `${convertedBlob.size} bytes`,
            mimetype: "audio/ogg",
          },
        ],
      };

      console.log("audioMessage: ", audioMessage);

      const backendPayload = prepareBackendPayload(audioMessage, contact.id);
      await sendToBackend(backendPayload);
    } catch (error) {
      console.error("Erro ao converter áudio para Base64:", error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <UserHead
        chatUserDetails={contact}
        pinnedTabs={[]}
        onOpenUserDetails={() => {}}
        onDelete={() => {}}
        isChannel={isChannel}
        onToggleArchive={() => {}}
      />

      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        <Box
          flexGrow={1}
          sx={{
            maxHeight: "calc(100vh - 150px)",
            zIndex: 1,
          }}
        >
          <Conversation
            chatUserConversations={{
              conversationId: String(contact?.id),
              messages: conversation?.messages ?? [],
            }}
            chatUserDetails={contact}
            onDelete={() => {}}
            onSetReplyData={setReplyData}
            isChannel={isChannel}
          />
        </Box>

        <ChatInputSection
          onSend={onSend}
          onSendAudio={onSendAudio}
          replyData={replyData}
          onSetReplyData={setReplyData}
          chatUserDetails={contact}
          isChannel={isChannel}
        />
      </Box>
    </Box>
  );
};

export default Index;
