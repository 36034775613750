import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import DescriptionIcon from "@mui/icons-material/Description";

interface StartButtonsProps {
  onSelectImages: (images: Array<any>) => void;
  onSelectFiles: (files: Array<any>) => void;
}

const StartButtons = ({ onSelectImages, onSelectFiles }: StartButtonsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "image" | "file",
  ) => {
    const files = Array.from(event.target.files || []);
    if (type === "image") {
      onSelectImages(files);
    } else {
      onSelectFiles(files);
    }
    handleCloseMenu();
  };

  return (
    <div>
      <Tooltip title="Attach">
        <IconButton onClick={handleOpenMenu}>
          <AttachFileIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          style: { width: 240, borderRadius: 8 },
        }}
      >
        {/* options photos and Videos */}
        <MenuItem>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <input
              type="file"
              hidden
              accept="image/*,video/*"
              onChange={e => handleFileSelection(e, "image")}
            />
            <ListItemIcon>
              <PhotoLibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Fotos e vídeos" />
          </label>
        </MenuItem>

        {/* Opção Documento */}
        <MenuItem>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <input
              type="file"
              hidden
              accept=".pdf,.docx,.xlsx,.txt"
              onChange={e => handleFileSelection(e, "file")}
            />
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Documento" />
          </label>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default StartButtons;
