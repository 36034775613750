import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Button, Typography, Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRedux } from "../../hooks/index";
import LogoutLayoutWrapper from "../../components/LogoutLayoutWrapper";
import withRouter from "../../components/withRouter";
import { logoutUser } from "../../redux/actions";
import PersonIcon from "@mui/icons-material/Person";

const Logout = (props: any) => {
  const { t } = useTranslation();
  const { dispatch } = useRedux();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return (
    <LogoutLayoutWrapper>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Grid item sm={8} lg={6} xl={5} className="col-xxl-4">
          <Box sx={{ py: { md: 5, xs: 4 }, textAlign: "center" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                background: `linear-gradient(to right, #4e3699 45%, #7956F7 100%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {t("You are logged out")}
            </Typography>
            <Button
              component={Link}
              to="/auth-login"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#4e3699",
                color: "white",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#3d2c7d",
                },
              }}
            >
              {t("Sign in")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LogoutLayoutWrapper>
  );
};

export default withRouter(Logout);
