import React, { useState } from "react";
import {
  Box,
  FormControl,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EndButtons from "./EndButtons";
import InputSection from "./InputSection";
import StartButtons from "./StartButtons";
import AudioRecorder from "./AudioRecorder";
import EmojiPicker from "./EmojiPicker";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

// interface
import {
  MessagesTypes,
  AttachmentTypes,
} from "../../../../data";

interface IndexProps {
  onSend: (data: MessagesTypes) => void;
  onSendAudio: (audioBlob: Blob) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
  isChannel: boolean;
}

const Index = ({
                 onSend,
                 onSendAudio,
                 replyData,
                 onSetReplyData,
                 chatUserDetails,
                 isChannel,
               }: IndexProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detecta se é mobile

  const [isRecording, setIsRecording] = useState(false);
  const [text, setText] = useState<string>("");
  const [files, setFiles] = useState<AttachmentTypes[] | undefined>(undefined);
  const [preview, setPreview] = useState<string | null>(null);

  const onChangeText = (value: string) => {
    setText(value);
  };

  const onSelectFiles = (selectedFiles: Array<any>) => {
    selectedFiles.forEach((file: any, key: number) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && typeof event.target.result === "string") {
          setFiles(prev => [
            ...(prev || []),
            {
              id: Date.now() + key,
              name: file.name,
              downloadLink: URL.createObjectURL(file),
              base64Data: typeof event.target?.result === 'string' ? event.target.result.split(',')[1] : '',
              desc: file.size,
              mimetype: file.type,
            },
          ]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const onSubmit = () => {
    const data: MessagesTypes = {
      mId: Date.now(),
      text,
      time: new Date().toISOString(),
      meta: {
        receiver: chatUserDetails.id,
        sender: "You",
        sent: true,
        received: false,
        read: false,
      },
      attachments: files?.map(file => ({
        ...file,
        base64: file.base64Data,
      })),
    };

    console.log("data: (dados do input)", data);

    setText("");
    setFiles(undefined);
    onSend(data);
  };

  return (
    <Box
      id="chat-conversation-list"
      sx={{
        position: "relative",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
        paddingBottom: { xs: "120px", sm: "60px" },
      }}
    >
      {/* Image preview */}
      {files && files.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "260px", sm: "80px" },
            left: { xs: "50%", sm: "0px" },
            transform: { xs: "translateX(-50%)", sm: "none" },
            width: "100%",
            maxWidth: "400px",
            backgroundColor: "transparent",
            borderRadius: "8px",
            padding: "10px",
            zIndex: 20,
          }}
        >
          <Box display="flex" flexWrap="wrap" gap={1}>
            {files.map((file) => (
              <Box
                key={file.id}
                position="relative"
                width="80px"
                height="80px"
                border="1px solid #ddd"
                borderRadius="8px"
                overflow="hidden"
                onClick={() => setPreview(file.downloadLink)}
                sx={{ cursor: "pointer" }}
              >
                <img
                  src={file.downloadLink}
                  alt={file.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />

                {/* Remove image button */}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFiles((prev) => prev?.filter((f) => f.id !== file.id));
                  }}
                  sx={{
                    position: "absolute",
                    top: 2,
                    right: 2,
                    backgroundColor: "rgba(0,0,0,0.6)",
                    color: "white",
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      )}



      {/* Inbox */}
      <Box
        className="chat-input-section"
        sx={{
          position: { xs: "fixed", sm: "absolute" },
          bottom: { xs: "80px", sm: "100px", md: "0px" },
          width: "100%",
          backgroundColor: "rgb(255,255,255)",
          zIndex: 10,
          padding: "8px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
        }}
      >
        <FormControl component="form" onSubmit={(e) => { e.preventDefault(); onSubmit(); }} fullWidth>
          <Box display="flex" alignItems="center" gap={0.2} sx={{ width: "100%" }}>
            <EmojiPicker onSelectEmoji={(emoji) => setText((prev) => prev + emoji)} />
            <StartButtons onSelectImages={onSelectFiles} onSelectFiles={onSelectFiles} />
            <Box flexGrow={1} sx={{ marginLeft: "4px" }}>
              {isRecording ? (
                <AudioRecorder onSendAudio={onSendAudio} onCancel={() => setIsRecording(false)} />
              ) : (
                <InputSection value={text} onChange={onChangeText} onSubmit={onSubmit} />
              )}
            </Box>
            <EndButtons onSubmit={onSubmit} isTextEmpty={text.trim() === ""} isRecording={isRecording} toggleRecording={() => setIsRecording(!isRecording)} hasAttachments={Boolean(files?.length)} />
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Index;